export const EDIT_RECIPE = 'EDIT_RECIPE';
export const IMPORT_RECIPE = 'IMPORT_RECIPE';
export const IMPORTED_RECIPE = 'IMPORTED_RECIPE';

export const editRecipe = (value) => ({
  type: EDIT_RECIPE,
  payload: value,
});
export const importRecipes = (value) => ({
  type: IMPORT_RECIPE,
  payload: value,
});
export const importedRecipes = (value) => ({
  type: IMPORTED_RECIPE,
  payload: value,
});
