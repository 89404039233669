import React from 'react';
import './styles.css';

function RoundedLabel({ value, unit, tooltipText, children }) {
  return (
    <div
      data-tooltip={tooltipText}
      className="flex-column shadow-box rounded-label"
    >
      {children}
      <div />
      <p>{value.toString() === '0' ? '-' : value}</p>
      {unit ? <p className="unit">{unit}</p> : <div className="unit" />}
    </div>
  );
}

export default RoundedLabel;
