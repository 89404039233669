import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoundedLabel from '../RoundedLabel';
import RoundedLabelEdit from '../RoundedLabelEdit';
import './styles.css';

// nutrition->
// calories
// fatContent
// servingSize
// proteinContent
// carbohydrateContent

function NutrientPanel({ nutrition, edit, onChange, factor = 1 }) {
  const { t } = useTranslation('translation', { keyPrefix: 'recipe' });
  const parseNutrition = (str) => ({
    value: !Number.isNaN(Number.parseFloat(str))
      ? Number.parseFloat(str) * factor ?? '-'
      : '-',
    unit: str?.toString()?.split(' ')[1] ?? null,
  });

  const [calories, setCalories] = useState(parseNutrition(nutrition?.calories));
  const [fatContent, setFatContent] = useState(
    parseNutrition(nutrition?.fatContent)
  );
  const [proteinContent, setProteinContent] = useState(
    parseNutrition(nutrition?.proteinContent)
  );
  const [carbohydrateContent, setCarbohydrateContent] = useState(
    parseNutrition(nutrition?.carbohydrateContent)
  );

  useEffect(() => {
    setCalories(parseNutrition(nutrition?.calories));
    setFatContent(parseNutrition(nutrition?.fatContent));
    setProteinContent(parseNutrition(nutrition?.proteinContent));
    setCarbohydrateContent(parseNutrition(nutrition?.carbohydrateContent));
  }, [nutrition, factor]);

  const RoundedLabelMode = !edit ? RoundedLabel : RoundedLabelEdit;

  return (
    <div className="flex-row nutrient-panel">
      <RoundedLabelMode
        tooltipText={t('tooltip-calories')}
        value={calories.value}
        unit={t('calories-unit')}
        max={1000}
        onChange={(val) => {
          onChange?.({ calories: `${val.value} ${val.unit}` });
        }}
      >
        {t('calories-text')}
      </RoundedLabelMode>
      <RoundedLabelMode
        tooltipText={t('tooltip-fat-content')}
        value={fatContent.value}
        unit={t('fat-content-unit')}
        max={1000}
        onChange={(val) => {
          onChange?.({ fatContent: `${val.value} ${val.unit}` });
        }}
      >
        {t('fat-content-text')}
      </RoundedLabelMode>
      <RoundedLabelMode
        tooltipText={t('tooltip-protein-content')}
        value={proteinContent.value}
        unit={t('protein-content-unit')}
        max={1000}
        onChange={(val) => {
          onChange?.({ proteinContent: `${val.value} ${val.unit}` });
        }}
      >
        {t('protein-content-text')}
      </RoundedLabelMode>
      <RoundedLabelMode
        tooltipText={t('tooltip-carbohydrate-content')}
        value={carbohydrateContent.value}
        unit={t('carbohydrate-content-unit')}
        max={1000}
        onChange={(val) => {
          onChange?.({ carbohydrateContent: `${val.value} ${val.unit}` });
        }}
      >
        {t('carbohydrate-content-text')}
      </RoundedLabelMode>
    </div>
  );
}

export default NutrientPanel;
