import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import TagLabel from '../TagLabel';
import './styles.css';

function TagInput(
  {
    propTags,
    placeholder,
    fontSize,
    style,
    onTagsChange,
    onInputChange,
    ...props
  },
  ref
) {
  const [tags, setTags] = useState(propTags ?? []);

  useEffect(() => {
    onTagsChange?.(tags);
  }, [tags]);

  useImperativeHandle(ref, () => ({
    setTag(tag) {
      if (!tags.includes(tag)) setTags([...tags, tag]);
    },
  }));

  return (
    <div className="tag-input flex-row" style={style || {}} {...props}>
      {tags.map((tag, index) => (
        <TagLabel
          deletable
          key={index}
          onDelete={() => {
            setTags(tags.filter((_, i) => i !== index));
          }}
        >
          {tag}
        </TagLabel>
      ))}
      <span>
        <input
          placeholder={placeholder}
          onChange={(e) => {
            onInputChange?.(e.target.value);
          }}
          style={{ fontSize: fontSize ?? '20px' }}
        />
      </span>
    </div>
  );
}

export default forwardRef(TagInput);
