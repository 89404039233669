import React, { useState, useEffect, useRef } from 'react';

function ControlledInput({ value, onChange, onKeyDown, ...rest }) {
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange?.(e);
  };

  return (
    <input
      ref={ref}
      value={value}
      onChange={handleChange}
      onKeyDown={(e) => onKeyDown?.(e)}
      {...rest}
    />
  );
}

export default ControlledInput;
