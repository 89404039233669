import React from 'react';
import Carousel from '../Carousel';
import defaultImage from '../../assets/images/default_recipe.png';
import './styles.css';

function RecipeImages({ name, images, startIndex }) {
  return (
    <div className="shadow-box">
      <div
        className="recipe-images"
        style={{
          flex: '1',
          width: '100%',
          maxHeight: '40vh',
          minHeight: '40vh',
          position: 'relative',
        }}
      >
        <Carousel startIndex={startIndex}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image.url || image}
              alt={defaultImage}
              style={{
                userSelect: 'none',
                objectFit: 'cover',
                borderRadius: 'inherit',
                height: '100%',
                width: '100%',
              }}
              onError={(e) => {
                e.target.src = defaultImage;
              }}
            />
          ))}
        </Carousel>
        <div
          className="flex-column"
          style={{
            position: 'absolute',
            left: '0px',
            right: '0px',
            bottom: '0px',
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <div className="recipe-title">
            <h1>{name}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecipeImages;
