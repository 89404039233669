import {
  ADD_COUNTDOWN,
  DELETE_COUNTDOWN,
  PAUSE_COUNTDOWN,
  PLAY_COUNTDOWN,
} from '../actions/countdown';

const recipesReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    counter: [],
  },
  action
) => {
  switch (action.type) {
    case ADD_COUNTDOWN:
      if (state.counter.find((c) => c.id === action.payload.id)) {
        // eslint-disable-next-line no-undef
        document
          .getElementById(`${action.payload.id}`)
          .classList.toggle('blink');
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          document
            .getElementById(`${action.payload.id}`)
            .classList.toggle('blink');
        }, 500);
        return state;
      }
      return {
        ...state,
        counter: [...state.counter, action.payload],
      };
    case DELETE_COUNTDOWN:
      return {
        ...state,
        counter: [...state.counter.filter((c) => c.id !== action.payload)],
      };
    case PAUSE_COUNTDOWN:
      return {
        ...state,
        counter: [
          ...state.counter.map((c) => {
            if (c.id === action.payload)
              return {
                ...c,
                state: 'paused',
              };
            return c;
          }),
        ],
      };
    case PLAY_COUNTDOWN:
      return {
        ...state,
        counter: [
          ...state.counter.map((c) => {
            if (c.id === action.payload)
              return {
                ...c,
                state: 'running',
              };
            return c;
          }),
        ],
      };
    default:
      return state;
  }
};

export default recipesReducer;
