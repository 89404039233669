import React from 'react';
import Icon from '../Icon';
import IconButton from '../IconButton';
import './styles.css';

function TagLabel({
  className,
  style,
  icon,
  deletable,
  children,
  onDelete,
  onClick,
  ...props
}) {
  return (
    <div
      className={`tag-label flex-row ${className}`}
      style={{ style }}
      onClick={() => {
        onClick?.();
      }}
      {...props}
    >
      {icon ? <Icon icon={icon} /> : null}
      <p
        style={{
          paddingRight: deletable ? '5px' : 'inherit',
        }}
      >
        {children}
      </p>
      {deletable ? (
        <IconButton
          className="clickable"
          icon="close"
          size={15}
          style={{
            backgroundColor: 'var(--background-color-primary)',
            color: 'gray',
            borderRadius: '50%',
          }}
          onClick={() => {
            onDelete?.();
          }}
        />
      ) : null}
    </div>
  );
}

export default TagLabel;
