/* eslint-disable no-debugger */
import React, { useState, useEffect } from 'react';
// import firebase from 'firebase/compat/app';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, signOut } from 'firebase/auth';

import { SignIn, UserCard } from '../../components';
import { logout } from '../../store/actions/user';
import './styles.css';

function User() {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [user, setUser] = useState(userState);

  useEffect(() => {
    setUser(userState);
  }, [userState]);

  const loggedInRender = () => (
    <UserCard
      user={user.user}
      onLogout={() => {
        const auth = getAuth();
        signOut(auth)
          .then(() => {
            // Sign-out successful.
          })
          .catch((error) => {
            // An error happened.
            console.error(error);
          });

        // dispatch(logout());
        dispatch({ type: 'RESET_APP' });
        dispatch(logout());
      }}
    />
  );
  const notLoggedInRender = () => (
    <div className="user-container">
      <SignIn />
    </div>
  );

  return (
    <div id="user">
      {user.loggedIn && user.user !== null
        ? loggedInRender()
        : notLoggedInRender()}
    </div>
  );
}

export default User;
