import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Icon from '../Icon';
import TagInput from '../TagInput';
import './styles.css';

function Searchbar(
  { tags, placeholder, fontSize, style, onInputChange, onTagsChange, ...props },
  ref
) {
  const tagInput = useRef(null);
  useImperativeHandle(ref, () => ({
    setTag(tag) {
      return tagInput.current.setTag(tag);
    },
  }));

  return (
    <div className="searchbar" style={style || {}} {...props}>
      <Icon icon="search" />
      <TagInput
        ref={tagInput}
        placeholder={placeholder}
        propTags={tags}
        onInputChange={(value) => {
          onInputChange?.(value);
        }}
        onTagsChange={(ts) => {
          onTagsChange?.(ts);
        }}
      />
    </div>
  );
}

export default forwardRef(Searchbar);
