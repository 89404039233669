import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import Router from '../../router';
import { Modal, Sidebar, CropImageModal } from '../../components';
import { addCountdown } from '../../store/actions/countdown';
import { syncRecipes } from '../../store/actions/recipes';
import { login } from '../../store/actions/user';
import { getRecipes, saveRecipes } from '../../services/database-handler';
import whatsnew01 from '../../assets/images/whatsnew_01.png';
import whatsnew02 from '../../assets/images/whatsnew_02.png';
import whatsnew03 from '../../assets/images/whatsnew_03.png';
import whatsnew04 from '../../assets/images/whatsnew_04.gif';
import './styles.css';

function Main() {
  const dev = process.env.NODE_ENV === 'development' && false;
  const location = useLocation();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);

  const state = useSelector((s) => s);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async (user) => {
        console.info('user', user);
        if (user?.multiFactor.user) {
          dispatch(login(user.multiFactor.user));

          getRecipes()
            .then((data) => {
              dispatch(syncRecipes(data));
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    if (process.env.NODE_ENV === 'development')
      console.debug('something changed:', state.recipes);
    saveRecipes(state.recipes.recipes).then(() => {
      // save recipes
    });
  }, [state.recipes]);

  useEffect(() => {
    console.info('route has been changed to ', location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    setImageModal(state.modal.imageModal.shown);
  }, [state.modal]);

  return (
    // <React.StrictMode  >
    <div className="main flex" style={{ position: 'relative' }}>
      <Sidebar
        onClickQuestion={() => {
          setModal(true);
        }}
      />
      <div
        className="main-container flex"
        style={{
          flexGrow: 1,
          position: 'relative',
        }}
      >
        <Router />

        {dev ? (
          <div
            className="flex-column"
            style={{
              position: 'fixed',
              right: '0px',
              padding: '10px',
              zIndex: '20',
              height: '100%',
              backgroundColor: '#ffffff20',
              maxWidth: '200px',
            }}
          >
            <button
              type="button"
              onClick={() => {
                dispatch(
                  addCountdown({
                    id: Math.random(),
                    recipe_id: 1,
                    starting_duration: 2,
                    duration: Math.random() * 100,
                    name: 'hallo',
                    state: 'paused',
                  })
                );
              }}
            >
              add counter
            </button>
            <input
              style={{
                marginTop: '100px',
                width: '200px',
                // transform: 'rotate(90deg)',
              }}
              type="file"
              onChange={(e) => {
                const { currentUser } = firebase.auth();

                const getBase64 = (file) =>
                  new Promise((resolve) => {
                    let baseURL = '';
                    // Make new FileReader
                    const reader = new FileReader();

                    // Convert the file to base64 text
                    reader.readAsDataURL(file);

                    // on reader load somthing...
                    reader.onload = () => {
                      // Make a fileInfo Object
                      baseURL = reader.result;
                      resolve(baseURL);
                    };
                  });

                getBase64(e.target.files[0]).then((image) =>
                  currentUser.getIdToken(true).then((token) =>
                    // const data = new FormData();
                    // const image = e.target.files[0];
                    // eslint-disable-next-line no-undef
                    fetch(
                      // 'https://europe-west1-recipe-app-343817.cloudfunctions.net/database-service/delete-recipes',
                      'http://localhost:5555/ocr-image',
                      {
                        method: 'POST',
                        body: JSON.stringify({
                          // img: image,
                          img: image.replace('data:image/png;base64,', ''),
                        }),
                        headers: {
                          Authorization: token,
                          'Content-Type': 'application/json',
                        },
                      }
                    )
                      .then((response) => response.json())
                      .then((d) => console.debug(d))
                      .catch((err) => console.debug(err))
                  )
                );
              }}
            />
          </div>
        ) : null}
      </div>
      <div id="demo-badged">ALPHA - DEMO</div>
      <CropImageModal id="image-modal" open={imageModal} />
      <Modal open={modal} onClose={() => setModal(false)}>
        <h1 id="richinstructiontext">Rich Instruction Text</h1>
        <p>
          Rich instruction text allow to divide quantities of an ingredient in
          the instruction by percentage or by factor. This information is then
          also converted when changing the portions. In addition, durations for
          cooking, baking and resting, for example, are automatically recognized
          from the instruction texts and a countdown timer can be started
          directly with just one click.
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 40px',
          }}
        >
          <img src={whatsnew01} alt="" />
        </div>
        <p style={{ whiteSpace: 'break-spaces' }}>
          {`To split the quantities specification in the statement text this syntax is used 
{<factor|percentage>|<name of ingredient>|<alias>} 
For example:
`}
          <strong>{`{1/2|butter|butter melted}
`}</strong>
          <strong>{`{0.5|butter|butter melted}
`}</strong>
          <strong>{`{20%|butter|butter melted}
          `}</strong>
        </p>
        <div
          className="double"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 40px',
          }}
        >
          <img src={whatsnew02} alt="" />
          <img src={whatsnew03} alt="" />
        </div>
        <p>
          {`With autocomplete, all you have to do is write "{" and you can select the ingredient. If the desired ingredient is not shown in the dropdown list, this can be forced by writing the first letters.`}
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 40px',
          }}
        >
          <img src={whatsnew04} alt="" />
        </div>
      </Modal>
    </div>
    // </React.StrictMode>
  );
}

export default Main;
