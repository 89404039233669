import React from 'react';
import Icon from '../Icon';
import './styles.css';

function SidebarButton({
  style,
  innerStyle,
  labeled,
  icon,
  className,
  iconProps,
  onClick,
  ...props
}) {
  return (
    <div
      className={`sidebar-button-container flex ${className || ''}`}
      onClick={() => {
        onClick?.();
      }}
      onKeyPress={() => {
        onClick?.();
      }}
      role="button"
      tabIndex={0}
      style={{ ...style }}
    >
      <div className="sidebar-button flex" {...props}>
        <Icon icon={icon} {...iconProps} />
      </div>
      {labeled ? <p>{labeled}</p> : null}
    </div>
  );
}

export default SidebarButton;
