import React from 'react';
import defaultImage from '../../assets/images/default_recipe.png';
import './styles.css';

function ImageTextContainer({
  // eslint-disable-next-line react/prop-types
  style,
  imageString,
  title,
  subTitle,
}) {
  return (
    <div
      className="image-container flex"
      style={{
        position: 'relative',
        ...style,
      }}
    >
      {title ? (
        <h2 className="image-text">
          {title
            .split(' ')
            .map((n, index) => <span key={index}>{`${n} `}</span>)
            .reverse()}
        </h2>
      ) : null}
      {subTitle ? <p className="image-text">{subTitle}</p> : null}
      <img
        alt="Cannot load"
        className="image-container-image"
        src={imageString || defaultImage}
        width="100%"
        height="100%"
        onError={(e) => {
          e.target.src = defaultImage;
        }}
      />
    </div>
  );
}

export default ImageTextContainer;
