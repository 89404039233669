/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import './styles.css';

function Card({ className, onClick, ...props }) {
  return (
    <div
      className={`card-container flex shadow-box ${className ?? ''}`}
      id={props?.id}
      onClick={() => {
        if (onClick) onClick();
      }}
      onKeyPress={() => {
        onClick?.();
      }}
      {...props}
    >
      {props.children}
    </div>
  );
}

export default Card;
