import { parse as mathParse, evaluate } from 'mathjs';
import convert from 'convert-units';

export const processIngredients = (ingredientsString, factor = 1) => {
  const processedIngredientsString = ingredientsString
    .replace(/\n/g, ' ')
    .trim();
  const [amount, unit, ...ingredient] = processedIngredientsString.split(' ');
  const checkIfAmountIsNaN = Number.isNaN(Number.parseFloat(amount));

  if (checkIfAmountIsNaN) return { error: ingredientsString };

  const mathString = `${amount}*${factor}`.replace(/,/g, '.');

  try {
    mathParse(mathString);
  } catch (error) {
    return {
      valueParseError: true,
      ingredient: ingredient.join(' '),
      isUnit: convert().possibilities('mass').includes(unit),
      value: amount,
      unit,
    };
  }

  let amountWithFactor = evaluate(mathString);
  let improvedUnit = unit;

  if (convert().possibilities('mass').includes(unit)) {
    ({ val: amountWithFactor, unit: improvedUnit } = convert(amountWithFactor)
      .from(unit)
      .toBest());
  }

  return {
    valueParseError: false,
    ingredient: ingredient.join(' '),
    isUnit: convert().possibilities('mass').includes(unit),
    value: amount,
    amountWithFactor,
    unit: improvedUnit,
  };
};

// eslint-disable-next-line arrow-body-style
export const processInstructions = () => {
  return '#';
};
