import React, { useState } from 'react';
import './styles.css';

function InputModal({
  title,
  description,
  text,
  errorMessage,
  onCancel,
  onConfirm,
}) {
  const [value, setValue] = useState(text);
  return (
    <div className="flex input-modal">
      <div className="input-popup">
        <h2>{title}</h2>
        <p>{description}</p>
        <input
          placeholder="Enter url"
          value={value ?? ''}
          onChange={(val) => {
            console.warn(val.target.value);
            setValue(val.target.value);
          }}
        />
        {errorMessage ? (
          <p style={{ color: 'red' }}>
            {errorMessage.toString().split('\n')[0]}
          </p>
        ) : null}
        <div className="input-popup-button-container flex-row">
          <button
            type="button"
            onClick={() => {
              onConfirm?.(value);
            }}
          >
            Confirm
          </button>
          <button
            type="button"
            onClick={() => {
              onCancel?.();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default InputModal;
