export const ADD_RECIPE = 'ADD_RECIPE';
export const ADD_OR_UPDATE_RECIPE = 'ADD_OR_UPDATE_RECIPE';
export const DELETE_RECIPE = 'DELETE_RECIPE';
export const ADD_RECIPES = 'ADD_RECIPES';
export const SYNC_RECIPES = 'SYNC_RECIPES';

export const addRecipe = (value) => ({
  type: ADD_RECIPE,
  payload: value,
});
export const addOrUpdateRecipe = (value) => ({
  type: ADD_OR_UPDATE_RECIPE,
  payload: value,
});
export const deleteRecipe = (value) => ({
  type: DELETE_RECIPE,
  payload: value,
});
export const addRecipes = (value) => ({
  type: ADD_RECIPES,
  payload: value,
});
export const syncRecipes = (value) => ({
  type: SYNC_RECIPES,
  payload: value,
});
