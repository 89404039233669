/* eslint-disable no-underscore-dangle */
import { createStore } from 'redux';
import allReducers from './reducers';
import { loadState, saveState } from './localStorage';

const persistedState = loadState();
const store = createStore(
  allReducers,
  persistedState,
  // eslint-disable-next-line no-undef
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
