/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import moment from 'moment';
import momentDuration from 'moment-duration-format';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { playCountdown, deleteCountdown } from '../../store/actions/countdown';
import './styles.css';
import IconButton from '../IconButton';

const useAudio = (url) => {
  // eslint-disable-next-line no-undef
  const [audio] = useState(new Audio(url));
  audio.loop = true;
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle, audio];
};

function CountDownSmall({ style, id, duration, isPlaying }) {
  const [playing, toggle, audio] = useAudio(
    // eslint-disable-next-line global-require
    require('../../assets/audio/ring.mp3')
  );
  const dispatch = useDispatch();

  const [isRunning, setIsRunning] = useState(false);
  useEffect(() => {
    setIsRunning(isPlaying);
  }, [isPlaying]);

  // eslint-disable-next-line no-unused-vars
  const [isCompleted, setIsCompleted] = useState(false);
  momentDuration(moment);
  // const pause = () => {
  //   dispatch(pauseCounter(id));
  //   setIsRunning(false);
  // };
  const play = () => {
    dispatch(playCountdown(id));
    setIsRunning(true);
  };
  const deleteTimer = () => {
    dispatch(deleteCountdown(id));
  };

  return (
    <div
      className="flex sidebar-button"
      id={id}
      style={{
        justifyContent: 'flex-start',
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto',
        ...style,
      }}
    >
      <IconButton
        icon="close"
        onClick={() => {
          if (playing) audio.pause();
          deleteTimer();
        }}
        size={15}
        style={{
          backgroundColor: 'var(--background-color-secondary)',
          color: 'white',
          position: 'absolute',
          top: '0px',
          right: '0px',
          borderRadius: '50%',
          zIndex: 9,
          padding: '2px',
        }}
      />
      <CountdownCircleTimer
        isPlaying={isRunning}
        size={40}
        strokeWidth={5}
        duration={duration}
        onComplete={() => {
          setIsCompleted(true);
          toggle();
        }}
        trailColor="gray"
        colors={['#008080', '#00ff00', '#ffc107', '#dc3545']}
        colorsTime={[duration * 0.7, duration * 0.5, duration * 0.2, 0]}
      >
        {({ remainingTime }) => (
          <div
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '10px',
              color: 'white',
            }}
            onClick={() => {
              play();
            }}
          >
            {isCompleted
              ? '0:00'
              : moment.duration(remainingTime, 'seconds').format()}
          </div>
        )}
      </CountdownCircleTimer>
    </div>
  );
}

export default CountDownSmall;
