import {
  SHOW_IMAGE_MODAL,
  CLOSE_IMAGE_MODAL,
  DELETE_IMAGE_MODAL,
} from '../actions/modal';

const recipesReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    imageModal: {
      shown: false,
    },
  },
  action
) => {
  switch (action.type) {
    case SHOW_IMAGE_MODAL:
      return {
        ...state,
        imageModal: {
          shown: true,
          image: action.payload,
          croppedImage: null,
        },
      };
    case CLOSE_IMAGE_MODAL:
      return {
        ...state,
        imageModal: {
          shown: false,
          image: null,
          croppedImage: action.payload,
        },
      };
    case DELETE_IMAGE_MODAL:
      URL.revokeObjectURL(state.imageModal.croppedImage);
      return {
        ...state,
        imageModal: {
          shown: false,
          image: null,
          croppedImage: null,
        },
      };
    default:
      return state;
  }
};

export default recipesReducer;
