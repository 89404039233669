/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { parse as mathParse, evaluate, typeOf } from 'mathjs';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tippy';
import { addCountdown, playCountdown } from '../../store/actions/countdown';
import Card from '../Card';
import './styles.css';
import IconButtonRounded from '../IconButtonRounded';

function InstructionCard({
  index,
  recipeId,
  instruction,
  recipeIngredient,
  className,
  style,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'recipe' });
  const dispatch = useDispatch();

  const counterStore = useSelector((state) => state.countdown);

  const timeUnitMinutesRegex = '[mM]inutes|[mM]inuten|[mM]inute|[mM]in';
  const timeUnitHoursRegex = '[sS]tunden|[sS]tunde|[hH]';
  const timeUnitSecondsRegex =
    '[sS]ekunden|[sS]ekunde|[sS]seconds|[sS]second|[sS]ec|[sS]ek';
  const allTimeUnitRegex = `${timeUnitMinutesRegex}|${timeUnitHoursRegex}|${timeUnitSecondsRegex}`;
  const regexCookingTime = `\\d*-*\\d+\\s*(${allTimeUnitRegex})`;
  const regexIngredients = `\\{(.*?\\|.*?\\|*.*?)\\}`;
  const regex = new RegExp(`(${regexCookingTime})|(${regexIngredients})`);

  let instructionText = instruction.text.split(regex);
  instructionText = instructionText.reduce((p, c) => {
    if (!c || (p.length > 0 && p[p.length - 1].text.includes(c))) return p;
    if (new RegExp(regexCookingTime).exec(c)) {
      const regData = new RegExp(`(\\d*-*\\d+)\\s*(${allTimeUnitRegex})`).exec(
        c
      );

      let timeUnit = 1;
      if (new RegExp(`(${timeUnitMinutesRegex})`).test(regData[2]))
        timeUnit = 60;
      else if (new RegExp(`(${timeUnitHoursRegex})`).test(regData[2]))
        timeUnit = 60 * 60;

      const data = {
        min: regData[1].split('-')[0],
        max: regData[1].split('-')?.[1] ?? regData[1].split('-')[0],
        avg:
          (Number.parseInt(
            regData[1].split('-')?.[1] ?? regData[1].split('-')[0],
            10
          ) +
            Number.parseInt(regData[1].split('-')[0], 10)) /
          2,
        timeUnit,
        name: `${recipeId}_${index}_${p.length + 1}`,
      };
      return [...p, { isTimer: true, data, text: c }];
    }
    return [...p, { text: c }];
  }, []);

  const renderHightlight = () =>
    instructionText.map((i, ind) => {
      if (i.isTimer) {
        const counter = counterStore.counter.find((c) => c.id === i.data.name);
        return (
          <Tooltip
            position="top"
            key={ind}
            trigger="mouseenter"
            html={
              <div
                className="recipe-tooltip"
                style={{
                  color: 'var(--highlight-color-primary)',
                }}
              >
                {t('tooltip-timer')}
                {i.text}
              </div>
            }
          >
            <strong
              className="clickable"
              style={{
                color: 'var(--highlight-color-primary)',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                if (!counter)
                  dispatch(
                    addCountdown({
                      id: `${recipeId}_${index}_${ind}`,
                      recipe_id: recipeId,
                      duration: i.data.avg
                        ? Number.parseFloat(i.data.avg, 10) * i.data.timeUnit
                        : Number.parseFloat(i.data.min, 10) * i.data.timeUnit,
                      name: instruction.name ?? `Step ${index + 1}`,
                    })
                  );
                dispatch(playCountdown(`${recipeId}_${index}_${ind}`));
              }}
              role="button"
            >
              {i.text}
            </strong>
          </Tooltip>
        );
      }
      if (new RegExp(regexIngredients).test(i.text)) {
        const ingr = new RegExp(regexIngredients).exec(i.text)[1].split('|');

        const ingrObject = recipeIngredient[ingr[1]];
        const mathString = `${ingr[0]} * (${ingrObject?.amount ?? 1})`;
        const mathNode = mathParse(mathString);
        return (
          <Tooltip
            position="top"
            key={ind}
            trigger="mouseenter"
            html={
              <div
                className="recipe-tooltip"
                style={{
                  color: 'var(--highlight-color-third)',
                }}
              >
                {ingr[0]}
                {t('tooltip-ingredients')}
                {ingrObject?.ingredient}
              </div>
            }
          >
            <strong
              style={{
                color: 'var(--highlight-color-third)',
                whiteSpace: 'nowrap',
              }}
              key={ind}
            >
              {typeOf(mathNode) === 'OperatorNode'
                ? `${evaluate(mathString)
                    .toFixed(2)
                    .replace(/[.,]00$/, '')} ${ingrObject?.unit ?? 'g'} ${
                    ingr[2] ?? ingr[1]
                  }`
                : i.text}
            </strong>
          </Tooltip>
        );
      }
      return i.text;
    });

  return (
    <>
      <Card
        className={`flex-column ${className ?? ''}`}
        style={{
          margin: '10px',
          marginTop: '5px',
          marginBottom: '0px',
          borderRadius: '2px',
          backgroundColor: 'var(--background-color-primary)',
          overflowWrap: 'break-word',
          ...style,
        }}
      >
        <div className="flex content">
          <img
            alt=""
            src={instruction.image}
            style={{
              maxWidth: '150px',
              objectFit: 'cover',
              borderRadius: 'inherit',
            }}
          />
          <div id="instructions" className="flex-column">
            {instruction.name ? (
              <h2
                className="instruction-title"
                style={
                  instruction.name
                    ? {}
                    : { fontStyle: 'italic', opacity: '0.5' }
                }
              >
                {instruction.name}
              </h2>
            ) : null}
            <div
              style={{
                height: '100%',
                display: 'block',
              }}
            >
              <p> {renderHightlight()}</p>
            </div>
          </div>
        </div>
      </Card>
      <div className="flex-row recipe-timer-row" style={{}}>
        {instructionText.map((i, ind) => {
          if (i.isTimer) {
            const timerText = `${i.text.split(/\s/g)[0]} ${i.text
              .split(/\s/g)[1]
              .substring(0, 3)}`;
            return (
              <div
                key={ind}
                className="flex recipe-timer"
                style={{
                  backgroundColor: 'var(--background-color-primary)',
                  padding: '5px 8px',
                  borderRadius: '20px',
                  color: 'var(--highlight-color-primary)',
                  marginTop: '5px',
                  alignItems: 'center',
                  marginRight: '5px',
                }}
              >
                <strong>{timerText}</strong>
                <IconButtonRounded
                  className="clickable"
                  icon="add"
                  padding={0}
                  style={{
                    backgroundColor: 'var(--highlight-color-primary)',
                    marginLeft: '5px',
                    // color: 'var(--background-color-primary)',
                    color: 'white',
                  }}
                  onClick={() => {
                    dispatch(
                      addCountdown({
                        id: i.data.name,
                        recipe_id: recipeId,
                        duration: i.data.avg
                          ? Number.parseFloat(i.data.avg, 10) * i.data.timeUnit
                          : Number.parseFloat(i.data.min, 10) * i.data.timeUnit,
                        name: instruction.name ?? `Step ${index + 1}`,
                      })
                    );
                  }}
                />
                <IconButtonRounded
                  className="clickable"
                  icon="play"
                  padding="0px"
                  style={{
                    backgroundColor: 'var(--highlight-color-primary)',
                    marginLeft: '5px',
                    // color: 'var(--background-color-primary)',
                    color: 'white',
                  }}
                  onClick={() => {
                    const counter = counterStore.counter.find(
                      (c) => c.id === i.data.name
                    );
                    if (!counter)
                      dispatch(
                        addCountdown({
                          id: i.data.name,
                          recipe_id: recipeId,
                          duration: i.data.avg
                            ? Number.parseFloat(i.data.avg, 10) *
                              i.data.timeUnit
                            : Number.parseFloat(i.data.min, 10) *
                              i.data.timeUnit,
                          name: instruction.name ?? `Step ${index + 1}`,
                        })
                      );
                    dispatch(playCountdown(i.data.name));
                  }}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
}

export default InstructionCard;
