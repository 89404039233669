import React from 'react';
import './styles.css';

function Home() {
  return (
    <div className="recipe">
      <div id="one" className="fill">
        <div className="container">
          <img
            src="https://img.chefkoch-cdn.de/rezepte/1167071222777296/bilder/1231517/crop-960x540/chili-con-carne.jpg"
            alt="temp"
          />
          <div className="img-text">
            <p>Chili con Carne</p>
            <p>
              Chili con Carne - mein Lieblings-Chili nach Jamies Art. \u00dcber
              1698 Bewertungen und f\u00fcr schmackhaft befunden. Mit \u25ba
              Portionsrechner \u25ba Kochbuch \u25ba Video-Tipps!
            </p>
          </div>
        </div>
      </div>
      <div id="two" className="fill" />
    </div>
  );
}

export default Home;
