import React from 'react';
import { useTranslation } from 'react-i18next';
import Duration from 'durationjs';
import Icon from '../Icon';
import RoundedLabel from '../RoundedLabel';
import './styles.css';
import RoundedLabelEdit from '../RoundedLabelEdit';

function RecipeTimePanel({ prepTime, cookingTime, totalTime, edit, onChange }) {
  const { t } = useTranslation('translation', { keyPrefix: 'recipe' });

  const RoundedLabelMode = !edit ? RoundedLabel : RoundedLabelEdit;
  return (
    <div className="flex-row recipe-time-panel">
      <RoundedLabelMode
        fixUnit
        tooltipText={t('tooltip-preperation-time')}
        min={0}
        max={600}
        value={
          prepTime?.includes('P') ? new Duration(prepTime)?.inMinutes() : '0'
        }
        unit="min"
        onChange={(val) => {
          onChange?.({ key: 'prepTime', value: `PT${val.value}M` });
        }}
      >
        <Icon icon="fork_with_knife" />
      </RoundedLabelMode>
      <RoundedLabelMode
        fixUnit
        tooltipText={t('tooltip-cooking-time')}
        min={0}
        max={600}
        value={
          cookingTime?.includes('P')
            ? new Duration(cookingTime)?.inMinutes()
            : '0'
        }
        unit="min"
        onChange={(val) => {
          onChange?.({ key: 'cookingTime', value: `PT${val.value}M` });
        }}
      >
        <Icon icon="cooking" />
      </RoundedLabelMode>
      <RoundedLabelMode
        fixUnit
        className="unit"
        tooltipText={t('tooltip-total-time')}
        min={0}
        max={600}
        value={
          totalTime?.includes('P') ? new Duration(totalTime)?.inMinutes() : '0'
        }
        unit="min"
        onChange={(val) => {
          onChange?.({ key: 'totalTime', value: `PT${val.value}M` });
        }}
      >
        <Icon icon="sum" />
      </RoundedLabelMode>
    </div>
  );
}

export default RecipeTimePanel;
