import {
  EDIT_RECIPE,
  IMPORT_RECIPE,
  IMPORTED_RECIPE,
} from '../actions/edit_recipe';

const recipesReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    recipe: {},
    state: {
      loading: false,
      loaded: false,
    },
  },
  action
) => {
  switch (action.type) {
    case EDIT_RECIPE:
      return {
        ...state,
        state: {
          ...state.state,
          loading: false,
          loaded: true,
        },
        recipe: action.payload,
      };
    case IMPORT_RECIPE:
      return {
        ...state,
        state: {
          ...state.state,
          loading: true,
          loaded: false,
        },
      };
    case IMPORTED_RECIPE:
      return {
        ...state,
        recipe: action.payload,
        state: {
          ...state.state,
          loading: false,
          loaded: true,
        },
      };
    default:
      return state;
  }
};

export default recipesReducer;
