export const ADD_COUNTDOWN = 'ADD_COUNTDOWN';
export const DELETE_COUNTDOWN = 'DELETE_COUNTDOWN';
export const PAUSE_COUNTDOWN = 'PAUSE_COUNTDOWN';
export const PLAY_COUNTDOWN = 'PLAY_COUNTDOWN';

export const addCountdown = (value) => ({
  type: ADD_COUNTDOWN,
  payload: value,
});
export const deleteCountdown = (value) => ({
  type: DELETE_COUNTDOWN,
  payload: value,
});
export const pauseCountdown = (value) => ({
  type: PAUSE_COUNTDOWN,
  payload: value,
});
export const playCountdown = (value) => ({
  type: PLAY_COUNTDOWN,
  payload: value,
});
