import { SIDEBAR_EXPAND, SIDEBAR_ACTIVE } from '../actions/sidebar';

const sideBarReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    expanded: false,
    active: 'recipes',
  },
  action
) => {
  switch (action.type) {
    case SIDEBAR_EXPAND:
      return {
        ...state,
        expanded: action.payload,
      };
    case SIDEBAR_ACTIVE:
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
};

export default sideBarReducer;
