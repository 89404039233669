// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC2tTC7rwJgZcFiPBPlHUgAz6B0cYrHhFM',
  authDomain: 'recipe-app-343817.firebaseapp.com',
  projectId: 'recipe-app-343817',
  storageBucket: 'recipe-app-343817.appspot.com',
  messagingSenderId: '91288594333',
  appId: '1:91288594333:web:4d6542797d192730b4b174',
  measurementId: 'G-M6H36WX8M7',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase.auth;
