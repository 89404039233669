import { v4 as uuidv4 } from 'uuid';
import constants from '../assets/constants/constants.json';

const loadRecipeFromURL = async (
  url = 'https://www.ndr.de/ratgeber/kochen/rezepte/rezeptdb6_id-9664_broadcast-1530_station-ndrtv.html'
) => {
  // eslint-disable-next-line no-undef
  const resp = await fetch(`${constants.ENDPOINT}/import-recipe?url=${url}`, {
    headers: {
      'Content-Type': 'text/html',
      // Origin: 'https://europe-central2-recipe-app-343817.cloudfunctions.net/',
    },
  });

  const jsonResp = await resp.json();
  if (!jsonResp.id) {
    jsonResp.id = uuidv4();
  }
  return jsonResp;
};
export default loadRecipeFromURL;
