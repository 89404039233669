import React from 'react';
import IconButtonRounded from '../IconButtonRounded';
import './styles.css';

function PortionBar({ portion, onClick }) {
  return (
    <div className="flex-row flex" id="portion-bar">
      <IconButtonRounded
        icon="remove"
        className="clickable"
        padding="5px"
        onClick={() => {
          onClick?.('-');
        }}
        style={{
          backgroundColor: 'var(--highlight-color-primary)',
          margin: '10px',
        }}
      />
      <p>Portionen: {portion}</p>
      <IconButtonRounded
        icon="add"
        padding="5px"
        className="clickable"
        onClick={() => {
          onClick?.('+');
        }}
        style={{
          backgroundColor: 'var(--highlight-color-primary)',
          margin: '10px',
        }}
      />
    </div>
  );
}

export default PortionBar;
