import React from 'react';
import NutrientPanel from '../NutrientPanel';
import RecipeTimePanel from '../RecipeTimePanel';
import './styles.css';

// nutrition->
// calories
// fatContent
// servingSize
// proteinContent
// carbohydrateContent

function MetaDataPanel({
  prepTime,
  cookingTime,
  totalTime,
  nutrition,
  factor,
  edit,
  onChange,
}) {
  return (
    <div className="flex-column meta-data-panel">
      <RecipeTimePanel
        prepTime={prepTime}
        cookingTime={cookingTime}
        totalTime={totalTime}
        edit={edit}
        onChange={(e) => {
          onChange?.({ ...e });
        }}
      />
      <NutrientPanel
        edit={edit}
        nutrition={nutrition}
        factor={factor}
        onChange={(e) => {
          onChange?.({ key: 'nutrition', value: e });
        }}
      />
    </div>
  );
}

export default MetaDataPanel;
