import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  FloatingButton,
  IconButtonRounded,
  IngredientsListEdit,
  InstructionCardEdit,
  MetaDataPanel,
  PortionBar,
  RecipeBase,
  RecipeImagesEdit,
} from '../../components';
import { addOrUpdateRecipe, deleteRecipe } from '../../store/actions/recipes';
import { deleteImages } from '../../services/image-handler';
import './styles.css';

function RecipeEdit() {
  const recipeStore = useSelector((state) => state.edit_recipe);

  const [recipe, setRecipe] = useState(recipeStore.recipe);
  const [factor, setFactor] = useState(recipe.portionFactor ?? 2);
  const [deletedImages] = useState([]);
  const [defaultImageIndex, setDefaultImageIndex] = useState(
    recipe.imageIndex ?? 0
  );

  const [recipeImages, setRecipeImages] = useState(
    Array.isArray(recipe.image) ? recipe.image : [recipe.image]
  );

  const ingredientsList = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (recipeStore.state.loading) return <div>Loading....</div>;

  const movingInstruction = (instruction, up = true) => {
    const tempInstructions = recipe.recipeInstructions;
    const currentIndex = recipe.recipeInstructions.findIndex(
      (i) => i === instruction
    );
    const tempInstruction = tempInstructions.splice(currentIndex, 1);
    tempInstructions.splice(
      currentIndex + (up ? -1 : 1),
      0,
      ...tempInstruction
    );
    setRecipe({
      ...recipe,
      recipeInstructions: tempInstructions,
    });
  };

  const movingIngeredients = (oldIndex, newIndex) => {
    const tempIngredients = recipe.recipeIngredient;
    const tempIngredient = tempIngredients.splice(oldIndex, 1);
    tempIngredients.splice(newIndex, 0, ...tempIngredient);
    setRecipe({
      ...recipe,
      recipeIngredient: tempIngredients,
    });
  };

  return (
    <RecipeBase
      leftChildren={
        <>
          <RecipeImagesEdit
            startIndex={defaultImageIndex}
            images={recipeImages}
            name={recipe.name}
            url={
              recipe.url ??
              recipe.mainEntityOfPage ??
              recipe.author?.url ??
              recipe.publisher?.url ??
              null
            }
            author={recipe.author?.name ?? recipe.publisher?.name ?? null}
            description={recipe.description}
            onDeleteImage={(index) => {
              if (recipeImages[index].bucket)
                deletedImages.push(recipeImages[index]);
              setRecipeImages([...recipeImages.filter((_, i) => i !== index)]);
            }}
            onChange={(type, value) => {
              switch (type) {
                case 'index':
                  setDefaultImageIndex(value);
                  break;
                case 'image':
                  if (value.url) {
                    setRecipeImages([...recipeImages, value]);
                    break;
                  }
                  setRecipeImages([
                    ...recipeImages,
                    {
                      url: value,
                    },
                  ]);
                  break;
                case 'name':
                  setRecipe({ ...recipe, name: value });
                  break;
                case 'description':
                  setRecipe({ ...recipe, description: value });
                  break;
                case 'author':
                  setRecipe({ ...recipe, author: { name: value } });
                  break;
                case 'url':
                  setRecipe({ ...recipe, url: value });
                  break;
                default:
                  break;
              }
            }}
          />
          <div id="recipe-left-scroll-container" className="flex-column">
            <MetaDataPanel
              edit
              nutrition={recipe.nutrition}
              prepTime={recipe.prepTime}
              cookingTime={recipe.cookingTime}
              totalTime={recipe.totalTime}
              onChange={(e) => {
                if (!recipe[e.key]) {
                  recipe[e.key] = e.value;
                } else if (typeof e.value === 'string') recipe[e.key] = e.value;
                else
                  recipe[e.key] = {
                    ...recipe[e.key],
                    ...e.value,
                  };
              }}
            />
            <div
              className="flex-column"
              style={{
                position: 'sticky',
                alignItems: 'center',
                top: '0',
              }}
            >
              <PortionBar
                portion={factor}
                onClick={(operation) => {
                  if (operation === '-') {
                    if (factor - 1 < 1) return;
                    setFactor(factor - 1);
                  }
                  if (operation === '+') {
                    setFactor(factor + 1);
                  }
                }}
              />
            </div>
            <IngredientsListEdit
              ref={ingredientsList}
              ingredients={recipe.recipeIngredient}
              onDragFinished={(oldIndex, newIndex) => {
                movingIngeredients(oldIndex, newIndex);
              }}
              onDelete={(index) => {
                setRecipe({
                  ...recipe,
                  recipeIngredient: recipe.recipeIngredient.filter(
                    (i, ind) => ind !== index
                  ),
                });
              }}
              onMerge={(index) => {
                const mergeText = `${recipe.recipeIngredient[index - 1]} ${
                  recipe.recipeIngredient[index]
                }`;
                const recipeIngredient = [...recipe.recipeIngredient];
                recipeIngredient[index - 1] = mergeText;
                setRecipe({
                  ...recipe,
                  recipeIngredient: recipeIngredient.filter(
                    (i, ind) => ind !== index
                  ),
                });
              }}
              onChange={(index, value) => {
                if (index === -1)
                  setRecipe({
                    ...recipe,
                    recipeIngredient: [
                      ...recipe.recipeIngredient,
                      ...value.split('\n').filter((r) => r !== ''),
                    ],
                  });
                else if (value.includes('\n')) {
                  setRecipe({
                    ...recipe,
                    recipeIngredient: recipe.recipeIngredient.reduce(
                      (p, c, i) => {
                        if (i === index) {
                          return [...p, ...value.split('\n')];
                        }
                        return [...p, c];
                      },
                      []
                    ),
                  });
                } else
                  setRecipe({
                    ...recipe,
                    recipeIngredient: recipe.recipeIngredient.map((i, ind) =>
                      ind === index ? value : i
                    ),
                  });
              }}
            />
          </div>
        </>
      }
      rightChildren={
        <>
          {recipe.recipeInstructions?.map((instruction, index) => (
            <div
              className="flex-row"
              key={index}
              style={{ alignItems: 'center', width: '100%' }}
            >
              <InstructionCardEdit
                ingredients={recipe.recipeIngredient}
                key={index}
                isFirst={index === 0}
                isLast={index === recipe.recipeInstructions.length - 1}
                instruction={instruction}
                style={{
                  width: '100%',
                  marginRight: '10px',
                }}
                onDelete={() => {
                  setRecipe({
                    ...recipe,
                    recipeInstructions: recipe.recipeInstructions.filter(
                      (i) => i !== instruction
                    ),
                  });
                }}
                onUp={() => {
                  movingInstruction(instruction);
                }}
                onDown={() => {
                  movingInstruction(instruction, false);
                }}
                onChange={(name, value) => {
                  setRecipe({
                    ...recipe,
                    recipeInstructions: [
                      ...recipe.recipeInstructions.map((i, ind) => {
                        const ins = i;
                        if (ind === index) ins[name] = value;
                        return ins;
                      }),
                    ],
                  });
                }}
              />
            </div>
          ))}
          <div
            className="flex-row"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <IconButtonRounded
              padding="15px"
              size={20}
              icon="add"
              className="recpie-edit-add-button clickable"
              onClick={() => {
                setRecipe({
                  ...recipe,
                  recipeInstructions: [
                    ...recipe.recipeInstructions,
                    {
                      '@type': 'HowToStep',
                      name: '',
                      text: '',
                    },
                  ],
                });
              }}
              style={{
                marginTop: '10px',
                backgroundColor: 'var(--background-color-primary)',
              }}
            />
            {false ? (
              <IconButtonRounded
                padding="15px"
                size={20}
                icon="camera_enhance"
                className="recpie-edit-add-button clickable"
                onClick={() => {
                  setRecipe({
                    ...recipe,
                    recipeInstructions: [
                      ...recipe.recipeInstructions,
                      {
                        '@type': 'HowToStep',
                        name: '',
                        text: '',
                      },
                    ],
                  });
                }}
                style={{
                  marginTop: '10px',
                  backgroundColor: 'var(--background-color-primary)',
                }}
              />
            ) : null}
          </div>
        </>
      }
    >
      <div
        className="floating-button-container"
        style={{
          position: 'absolute',
          right: '0px',
          bottom: '0px',
          display: 'flex',
          height: 'auto',
          zIndex: '10',
        }}
      >
        <FloatingButton
          style={{
            position: 'relative',
            marginLeft: '10px',
            backgroundColor: 'indianred',
          }}
          icon="delete"
          onClick={() => {
            dispatch(deleteRecipe(recipe.id));
            navigate(`../`);
          }}
        />
        <FloatingButton
          icon="close"
          style={{
            position: 'relative',
            marginLeft: '10px',
          }}
          onClick={() => {
            navigate(`../recipe/${recipe.id}`);
          }}
        />
        <FloatingButton
          icon="save"
          style={{
            position: 'relative',
            marginLeft: '10px',
          }}
          onClick={() => {
            const newIngredientData = ingredientsList.current.getInput();
            if (deletedImages.length > 0) deleteImages(deletedImages);
            if (newIngredientData)
              setRecipe({
                ...recipe,
                recipeIngredient: [
                  ...recipe.recipeIngredient,
                  newIngredientData,
                ],
              });
            dispatch(
              addOrUpdateRecipe({
                ...recipe,
                recipeIngredient: newIngredientData
                  ? [...recipe.recipeIngredient, newIngredientData]
                  : recipe.recipeIngredient,
                portionFactor: factor,
                imageIndex:
                  defaultImageIndex +
                  (defaultImageIndex > recipeImages.length - 1 ? -1 : 0),
                image: recipeImages,
              })
            );
            navigate(`../recipe/${recipe.id}`);
          }}
        />
      </div>
    </RecipeBase>
  );
}

export default RecipeEdit;
