export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const login = (value) => ({
  type: LOGIN,
  payload: value,
});
export const logout = (value) => ({
  type: LOGOUT,
  payload: value,
});
