import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './styles.css';

function MyCarousel({ startIndex, children, onChange }) {
  const [index, setIndex] = useState(startIndex);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    onChange?.(selectedIndex);
  };
  return (
    <Carousel
      indicators={children.length > 1}
      touch
      activeIndex={index}
      onSelect={handleSelect}
      interval={null}
      controls={children.length > 1}
    >
      {children.map((child, childIndex) => (
        <Carousel.Item key={childIndex}>{child}</Carousel.Item>
      ))}
    </Carousel>
  );
}

export default MyCarousel;
