import React, { useRef, useState } from 'react';
import './styles.css';

function RoundedLabelEdit({
  value,
  unit,
  max,
  tooltipText,
  onChange,
  children,
}) {
  const [val, setVal] = useState(value);

  const inputValue = useRef(null);

  return (
    <div
      data-tooltip={tooltipText}
      className="flex-column shadow-box rounded-label edit"
    >
      {children}
      <input
        ref={inputValue}
        type="number"
        placeholder="2000"
        value={val ?? ''}
        min={0}
        max={max}
        onInput={(e) => {
          const numValue =
            e.target.value === ''
              ? 0
              : Number.parseInt(e.target.value.replace(/[^0-9.]/g, ''), 10);
          if (numValue >= 0 && numValue < 10000) {
            onChange?.({
              value: numValue,
              unit,
            });
            setVal(numValue);
          }
        }}
      />
      {unit ? (
        <p className="unit edit">{unit}</p>
      ) : (
        <div className="unit edit" />
      )}
    </div>
  );
}

export default RoundedLabelEdit;
