export const SHOW_IMAGE_MODAL = 'SHOW_IMAGE_MODAL';
export const CLOSE_IMAGE_MODAL = 'CLOSE_IMAGE_MODAL';
export const DELETE_IMAGE_MODAL = 'DELETE_IMAGE_MODAL';

export const showImageModal = (value) => ({
  type: SHOW_IMAGE_MODAL,
  payload: value,
});
export const closeImageModal = (value) => ({
  type: CLOSE_IMAGE_MODAL,
  payload: value,
});
export const deleteImageModal = (value) => ({
  type: DELETE_IMAGE_MODAL,
  payload: value,
});
