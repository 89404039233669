export const SIDEBAR_EXPAND = 'SIDEBAR_EXPAND';
export const SIDEBAR_ACTIVE = 'SIDEBAR_ACTIVE';

export const expandSidebar = (value) => ({
  type: SIDEBAR_EXPAND,
  payload: value,
});
export const setActive = (value) => ({
  type: SIDEBAR_ACTIVE,
  payload: value,
});
