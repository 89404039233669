import React from 'react';
import { Routes, Route } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { Recipe, RecipeEdit, Recipes, Settings, User } from '../pages';

function Router() {
  return (
    <Routes>
      <Route path="/recipe/:id" element={<Recipe />} />
      <Route path="/recipe-edit" element={<RecipeEdit />} />
      <Route path="/" element={<Recipes />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/user" element={<User />} />
      {/* <Route path="/recipes" element={<Recipes />} /> */}
    </Routes>
  );
}

export default Router;
