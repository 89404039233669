/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import IconButtonRounded from '../IconButtonRounded';
import './styles.css';

function InputModal({ open, children, onClose }) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div
      id="modal-back"
      className="modal"
      style={
        isOpen
          ? {
              display: 'flex',
            }
          : {}
      }
      onClick={(e) => {
        if (e.target.id === 'modal-back') onClose?.();
      }}
    >
      <div className="modal-popup">
        <div className="header">
          <p>What&apos;s new</p>
          <IconButtonRounded icon="close" onClick={() => onClose?.()} />
        </div>
        <div className="content">
          {children}
          <div className="footer">
            <button
              type="button"
              onClick={() => {
                onClose?.();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InputModal;
