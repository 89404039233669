import React from 'react';
import { fraction } from 'mathjs';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tippy';
import './styles.css';

function IngredientsList({ ingredients }) {
  const { t } = useTranslation('translation', { keyPrefix: 'recipe' });
  const renderIngredientsList = (ingredientObject, index) => {
    if (ingredientObject.error) return <p>{ingredientObject.error}</p>;

    if (ingredientObject.valueParseError)
      return (
        <div className="flex">
          <Tooltip
            position="top"
            key={index}
            trigger="mouseenter"
            html={
              <div
                className="recipe-tooltip"
                style={{
                  color: 'indianred',
                }}
              >
                {t('tooltip-invalid-number')}
              </div>
            }
          >
            <strong className="clickable" style={{ color: 'indianred' }}>
              {ingredientObject.value}
            </strong>
          </Tooltip>
          {[ingredientObject.unit, ingredientObject.ingredient].join(' ')}
        </div>
      );

    const tenth = Number.parseInt(ingredientObject.amountWithFactor, 10);
    const decimalPlaces = ingredientObject.amountWithFactor - tenth;
    const fractionNumber = fraction(
      Number.isNaN(decimalPlaces) ? 1 : decimalPlaces
    );

    if (
      [1, 3].includes(fractionNumber.n) &&
      [1, 2, 4].includes(fractionNumber.d)
    ) {
      const dicCharcode = {
        12: 189,
        14: 190,
        34: 188,
      };
      return (
        <p style={{ wordWrap: 'wrap' }}>
          <strong>
            {tenth > 0 ? tenth : null}
            {String.fromCharCode(
              dicCharcode[`${fractionNumber.n}${fractionNumber.d}`]
            )}
          </strong>
          {[' ', ingredientObject.unit, ingredientObject.ingredient].join(' ')}
        </p>
      );
    }

    return (
      <p style={{ wordWrap: 'wrap' }}>
        <strong>
          {ingredientObject.amountWithFactor
            .toFixed(2)
            .replace(/[.,]00$/, '')
            .replace(/([.,]\d)0$/, '$1')}
        </strong>
        {[' ', ingredientObject.unit, ingredientObject.ingredient].join(' ')}
      </p>
    );
  };

  return (
    <div
      className="flex-column ingredients-list"
      style={{
        justifyContent: 'center',
        padding: '20px',
        paddingBottom: '80px',
        color: 'white',
      }}
    >
      {ingredients.map((i, index) => (
        <div key={index} style={{ maringTop: '5px' }}>
          {i.error !== '' ? renderIngredientsList(i, index) : null}
        </div>
      ))}
    </div>
  );
}

export default IngredientsList;
