import React from 'react';
import IconButton from '../IconButton';
import './styles.css';

function IconButtonRounded({
  icon,
  padding,
  size,
  className,
  onClick,
  style,
  ...props
}) {
  return (
    <IconButton
      icon={icon}
      size={size}
      className={`icon-button-rounded ${className ?? ''}`}
      onClick={() => {
        onClick?.();
      }}
      onKeyPress={() => {
        onClick?.();
      }}
      tabIndex={0}
      style={{
        padding: `${Number.parseFloat(padding ?? 10)}px`,
        width: 'fit-content',
        height: 'fit-content',
        backgroundColor: 'var(--highlight-color-primary)',
        ...style,
      }}
      {...props}
    />
  );
}

export default IconButtonRounded;
