import { combineReducers } from 'redux';
import User from './user';
import EditRecipe from './edit_recipe';
import Recipes from './recipes';
import Sidebar from './sidebar';
import Countdown from './countdown';
import Modal from './modal';

const allReducers = combineReducers({
  user: User,
  edit_recipe: EditRecipe,
  recipes: Recipes,
  sidebar: Sidebar,
  countdown: Countdown,
  modal: Modal,
});

const rootReducer = (state, action) => {
  console.info(action);
  if (action.type === 'RESET_APP') {
    return undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;
