import React, { useEffect, useState, useRef } from 'react';
import Carousel from '../../Carousel';
import defaultImage from '../../../assets/images/default_recipe.png';
import './styles.css';
import IconButtonRounded from '../../IconButtonRounded';
import ControlledInput from '../../ControlledInput';
import InputModal from '../../InputModal';
import { uploadImage } from '../../../services/image-handler';

function RecipeImagesEdit({
  name,
  description,
  // startIndex,
  url,
  author,
  images,
  onDeleteImage,
  onChange,
}) {
  const [modal, setModal] = useState(false);
  const [recipeName, setRecipeName] = useState('');
  const [recipeDescription, setRecipeDescription] = useState('');
  const [recipeAuthor, setRecipeAuthor] = useState('');
  const [recipeUrl, setRecipeUrl] = useState('');
  const [loadingImage, setLoadingImage] = useState(null);

  const inputRef = useRef(null);

  useEffect(() => {
    setRecipeName(name);
    setRecipeDescription(description);
  }, [name, description]);
  useEffect(() => {
    setRecipeUrl(url);
  }, [url]);
  useEffect(() => {
    setRecipeAuthor(author);
  }, [author]);
  return (
    <>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        onChange={(e) => {
          if (e.target.files.length > 0)
            setLoadingImage(
              uploadImage(e.target.files[0]).then((data) => {
                onChange?.('image', {
                  // eslint-disable-next-line no-underscore-dangle
                  url: `https://storage.cloud.google.com/${data.bucket}/${data.path_}`,
                  bucket: data.bucket,
                  // eslint-disable-next-line no-underscore-dangle
                  path: data.path_,
                });
                e.target.value = '';
              })
            );
        }}
      />
      {modal ? (
        <InputModal
          title="Import Recipe"
          description="Insert link off an website with an recipe on it. Please take note that not every recipe from a website can be parsed!"
          text=""
          // errorMessage={modalError.error ? modalError.message : false}
          onCancel={() => {
            setModal(false);
          }}
          onConfirm={(input) => {
            onChange?.('image', input);
            setModal(false);
          }}
        />
      ) : null}
      <div
        className="recipe-images"
        style={{
          flex: '1',
          width: '100%',
          maxHeight: '40vh',
          minHeight: '40vh',
          position: 'relative',
        }}
      >
        <Carousel
          startIndex={[...images, 'newImage'].length - 1}
          onChange={(index) => {
            onChange?.('index', index);
          }}
        >
          {[...images, 'newImage'].map((image, index) => (
            <div
              key={index}
              style={{ height: 'inherit', position: 'relative' }}
            >
              {image !== 'newImage' ? (
                <img
                  src={image.url || image}
                  alt={defaultImage}
                  style={{
                    userSelect: 'none',
                    objectFit: 'cover',
                    borderRadius: 'inherit',
                    height: '100%',
                    width: '100%',
                  }}
                  onError={(e) => {
                    e.target.src = defaultImage;
                  }}
                />
              ) : null}
              <IconButtonRounded
                type="input"
                className="clickable"
                icon={image !== 'newImage' ? 'delete' : 'add'}
                padding="20px"
                loading={loadingImage}
                loadingPadding={8}
                style={{
                  color: 'white',
                  backgroundColor: 'var(--background-color-primary)',
                  position: 'absolute',
                  marginBottom: 'auto',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                onClick={() => {
                  if (image !== 'newImage') onDeleteImage?.(index);
                  else {
                    inputRef.current.click();
                    // setModal(true);
                  }
                }}
              />
            </div>
          ))}
        </Carousel>
        <div
          className="flex-column"
          style={{
            position: 'absolute',
            left: '0px',
            right: '0px',
            bottom: '0px',
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <div className="recipe-title">
            <ControlledInput
              placeholder="text"
              value={recipeName}
              onInput={(e) => {
                onChange?.('name', e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="recipe-description  shadow-box">
        <textarea
          placeholder="text"
          value={recipeDescription}
          onInput={(e) => {
            onChange?.('description', e.target.value);
          }}
        />
        <input
          placeholder="author"
          value={recipeAuthor}
          onInput={(e) => {
            onChange?.('author', e.target.value);
          }}
        />
        <input
          placeholder="url"
          value={recipeUrl}
          onInput={(e) => {
            onChange?.('url', e.target.value);
          }}
        />
      </div>
    </>
  );
}

export default RecipeImagesEdit;
