import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import {
  Card,
  FloatingButton,
  IconButtonRounded,
  ImageTextContainer,
  InputModal,
  Searchbar,
  TagLabel,
} from '../../components';
import { editRecipe } from '../../store/actions/edit_recipe';
import loadrecipe from '../../services/importer';
import './styles.css';
import { setActive } from '../../store/actions/sidebar';
import defaultImage from '../../assets/images/default_recipe.png';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Recipes() {
  const query = useQuery();
  const filter = JSON.parse(query.get('filter'));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const recipesStore = useSelector((state) => state.recipes);

  const searchRef = useRef(null);

  const [modal, setModal] = useState(false);
  const [clipBoard, setClipboard] = useState('');
  const [modalError, setModalError] = useState({ error: false, message: '' });

  const [tags, setTags] = useState(filter ?? []);
  const [searchInput, setSearchInput] = useState('');
  const [recipes, setRecipes] = useState(
    tags?.length > 0
      ? recipesStore.recipes.filter((recipe) => {
          const cat = recipe.recipeCategory;
          return cat?.some?.((r) => tags.includes(r)) ?? tags.includes(cat);
        })
      : recipesStore.recipes
  );

  useEffect(() => {
    setRecipes(
      recipesStore.recipes.filter((recipe) => {
        const cat = recipe.recipeCategory;

        const includesCategory =
          tags?.length > 0
            ? cat?.some?.((r) => tags.includes(r)) ?? tags.includes(cat)
            : true;

        const includesName = recipe.name
          .toLowerCase()
          .includes(searchInput.toLowerCase());
        return includesCategory && includesName;
      })
    );
  }, [searchInput, tags]);

  return (
    <div id="recipes" className="flex-column" style={{}}>
      <div id="recipes-search-box">
        <Searchbar
          ref={searchRef}
          placeholder="Search"
          tags={tags}
          onTagsChange={(ts) => {
            setTags(ts);
          }}
          onInputChange={(val) => {
            setSearchInput(val);
          }}
        />
        <div
          className="flex-row"
          style={{ gap: '4px', marginTop: '5px', flexWrap: 'wrap' }}
        >
          {recipesStore.recipes
            .reduce(
              (p, c) => [
                ...p,
                ...(Array.isArray(c.recipeCategory)
                  ? c.recipeCategory
                  : [c.recipeCategory]),
              ],
              []
            )
            .reduce((p, c) => {
              if (c === undefined || (p.includes(c) && c !== '')) return p;
              return [...p, c];
            }, [])
            .reduce((p, c) => (tags.includes(c) ? p : [...p, c]), [])
            .map((i, index) =>
              index < 5 ? (
                <TagLabel
                  key={index}
                  className="clickable shadow-box"
                  onClick={() => {
                    searchRef.current.setTag(i);
                  }}
                >
                  {i}
                </TagLabel>
              ) : null
            )}
        </div>
      </div>
      {modal ? (
        <InputModal
          title="Import Recipe"
          description="Insert link off an website with an recipe on it. Please take note that not every recipe from a website can be parsed!"
          text={clipBoard}
          errorMessage={modalError.error ? modalError.message : false}
          onCancel={() => {
            setModal(false);
          }}
          onConfirm={(input) => {
            loadrecipe(input)
              .then((resp) => {
                setModal(false);
                if (resp.error) {
                  return;
                }
                dispatch(editRecipe(resp));
                navigate(`../recipe-edit`);
              })
              .catch((err) => {
                setModalError({ error: true, message: err });
              });
          }}
        />
      ) : null}
      <FloatingButton
        className="import-recipe-button"
        icon="add_link"
        onClick={async () => {
          const clip =
            (await navigator.clipboard?.readText().catch((err) => {
              console.error(err);
            })) ?? '';
          setClipboard(clip);
          setModal(true);
        }}
      />
      <Masonry
        breakpointCols={{
          default: 4,
          1100: 3,
          700: 2,
          500: 1,
        }}
        className="my-masonry-grid flex"
        columnClassName="my-masonry-grid_column"
      >
        <div
          className="recipes-card-container"
          style={{
            minHeight: '150px',
            maxHeight: '250px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* <Searchbar
            onChange={(val) => {
              setRecipes(
                recipesStore.recipes.filter((r) =>
                  r.name.toLowerCase().includes(val.toLowerCase())
                )
              );
            }}
          /> */}
          <Card
            className="clickable wf-box"
            style={{
              flex: 1,
              backgroundColor: 'var(--background-color-primary)',
              maxWidth: '100%',
              borderRadius: '3px',
              justifyContent: 'center',
              alignItems: 'center',
              // marginTop: '3px',
            }}
            onClick={() => {
              dispatch(
                editRecipe({
                  id: uuidv4(),
                  '@context': 'http://schema.org',
                  '@type': 'Recipe',
                  name: '',
                  url: '',
                  mainEntityOfPage: '',
                  description: '',
                  comment: [],
                  image: [''],
                  author: {
                    '@type': '',
                    name: '',
                  },
                  publisher: {
                    '@type': '',
                    name: '',
                  },
                  datePublished: '',
                  keywords: '',
                  prepTime: '',
                  totalTime: '',
                  recipeYield: '',
                  recipeCategory: '',
                  recipeIngredient: [],
                  recipeCuisine: '',
                  recipeInstructions: [],
                })
              );
              navigate(`../recipe-edit`);
            }}
          >
            <IconButtonRounded
              icon="add"
              padding={20}
              style={{
                color: 'white',
                backgroundColor: 'var(--background-color-secondary)',
              }}
            />
          </Card>
        </div>
        {recipes.map((r) => (
          <Card
            className="recipes-card-container clickable wf-box"
            style={{
              height: 'auto',
              width: 'auto',
              // minHeight: `200px`,
              minHeight: `${150 + (50 / r.name.length) * 20}px`,
              // minWidth: '100%',
              maxWidth: '100%',
              borderRadius: '3px',
            }}
            key={r.id}
            onClick={() => {
              dispatch(setActive('none'));
              navigate(`../recipe/${r.id}`);
            }}
          >
            <ImageTextContainer
              imageString={
                r.image[r.imageIndex ?? 0]?.url ??
                r.image[r.imageIndex ?? 0] ??
                r.image.url ??
                null
              }
              defaultImage={defaultImage}
              title={r.name.substring(0, 50)}
              // subTitle={r.description.substring(0, 100)}
            />
          </Card>
        ))}
      </Masonry>
    </div>
  );
}

export default Recipes;
