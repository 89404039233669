/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useSelector, useDispatch } from 'react-redux';
import { closeImageModal } from '../../store/actions/modal';
import IconButtonRounded from '../IconButtonRounded';
import canvasPreview from './canvasPreview';
import './styles.css';

function CropImageModal({ open }) {
  const [crop, setCrop] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  const image = useRef(null);

  const dispatch = useDispatch();
  const imageModal = useSelector((s) => s.modal.imageModal);

  function toBlob(canvas) {
    return new Promise((resolve) => {
      canvas.toBlob(resolve);
    });
  }

  return (
    <div
      className="crop-image-modal"
      style={{ display: open ? 'flex' : 'none' }}
    >
      <div className="inner-container">
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => {
            setCrop(percentCrop);
          }}
          onComplete={(c) => {
            setCrop(c);
          }}
        >
          <img
            id="crop-image"
            ref={image}
            src={imageModal.image}
            alt="crop"
            height="400vw"
            onLoad={(e) => {
              const { width, height } = e.currentTarget;
              setCrop({ unit: 'px', x: 0, y: 0, width, height });
            }}
          />
        </ReactCrop>
        <div className="flex-row" style={{ gap: '10px' }}>
          <IconButtonRounded
            className="clickable"
            icon="check"
            style={{ backgroundColor: 'var(--highlight-color-primary)' }}
            onClick={async () => {
              const canvas = document.createElement('canvas');
              canvasPreview(
                document.getElementById('crop-image'),
                canvas,
                crop,
                1,
                0
              );

              const blob = await toBlob(canvas);

              const previewUrl = URL.createObjectURL(blob);
              canvas.remove();
              dispatch(closeImageModal(previewUrl));
            }}
          />
          <IconButtonRounded
            icon="close"
            className="clickable"
            style={{ backgroundColor: 'indianred' }}
            onClick={async () => {
              dispatch(closeImageModal(null));
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CropImageModal;
