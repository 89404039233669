import React from 'react';
import './styles.css';
import {
  SvgBook,
  SvgMenu,
  SvgMenuOpen,
  SvgPauseCircle,
  SvgPlayCircle,
  SvgStopCircle,
  SvgRemoveCircle,
  SvgRemove,
  SvgSettings,
  SvgSuggestion,
  SvgClose,
  SvgShortcut,
  SvgUpload,
  SvgEdit,
  SvgAdd,
  SvgSave,
  SvgDelete,
  SvgAddLink,
  SvgPlay,
  SvgArrowUp,
  SvgArrowDown,
  SvgArrowRight,
  SvgArrowLeft,
  SvgQuestion,
  SvgForkWithKnife,
  SvgCooking,
  SvgSum,
  SvgMerge,
  SvgSearch,
  SvgCameraEnhance,
  SvgCheck,
} from '../../assets/icons';

const icons = {
  book: SvgBook,
  menu_open: SvgMenuOpen,
  menu: SvgMenu,
  pause_circle: SvgPauseCircle,
  play_circle: SvgPlayCircle,
  suggestion: SvgSuggestion,
  settings: SvgSettings,
  stop_circle: SvgStopCircle,
  remove_circle: SvgRemoveCircle,
  remove: SvgRemove,
  close: SvgClose,
  shortcut: SvgShortcut,
  upload: SvgUpload,
  edit: SvgEdit,
  add: SvgAdd,
  save: SvgSave,
  add_link: SvgAddLink,
  delete: SvgDelete,
  play: SvgPlay,
  arrow_up: SvgArrowUp,
  arrow_down: SvgArrowDown,
  arrow_right: SvgArrowRight,
  arrow_left: SvgArrowLeft,
  question: SvgQuestion,
  fork_with_knife: SvgForkWithKnife,
  cooking: SvgCooking,
  sum: SvgSum,
  merge: SvgMerge,
  search: SvgSearch,
  camera_enhance: SvgCameraEnhance,
  check: SvgCheck,
};

function Icon({ badge, icon, iconSize, badgeNumber, ...props }) {
  if (!icons[icon ?? 'book'])
    console.warn(
      `Icon not found: %c${icon}%c`,
      'color: gold; font-weight: bold;font-size:15px;',
      'color: inherit; font-weight: normal;;font-size:inherit;'
    );
  const SpecificIcon = icons[icon ?? 'book'] ?? icons.book;
  if (badge) {
    return (
      <div className="flex" style={{}}>
        <p
          style={{
            position: 'absolute',
            right: '-8px',
            top: '-8px',
            backgroundColor: 'red',
            fontSize: '12px',
            borderRadius: '100%',
            width: '16px',
            height: '16px',
            textAlign: 'center',
          }}
        >
          {badgeNumber}
        </p>
        <SpecificIcon size={iconSize ?? 24} {...props} />
      </div>
    );
  }
  return <SpecificIcon size={iconSize ?? 24} {...props} />;
}

export default Icon;
