import firebase from 'firebase/compat/app';
import constants from '../assets/constants/constants.json';

export default (image) =>
  new Promise((res, rej) => {
    const { currentUser } = firebase.auth();

    const getBase64 = (file) =>
      new Promise((resolve) => {
        let baseURL = '';

        const reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
      });

    getBase64(image).then((img) =>
      currentUser.getIdToken(true).then((token) =>
        fetch(
          `${constants.ENDPOINT}/ocr-image`,
          // 'http://localhost:5555/upload-image',
          {
            method: 'POST',
            body: JSON.stringify({
              // img: image,
              img: img.replace(/data:image\/\w*;base64,/, ''),
            }),
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
            },
          }
        )
          .then((response) => response.json())
          .then((d) => res(d))
          .catch((err) => rej(err))
      )
    );
  });
