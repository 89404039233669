import React, { useState, useEffect } from 'react';
import Icon from '../Icon';
import './styles.css';

function IconButton({
  className,
  onClick,
  style,
  draggable,
  onDragStart,
  dataDraggable,
  tooltipText,
  loading,
  loadingPadding,
  iconStyle,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(typeof loading?.then === 'function' ?? false);
    loading
      ?.then(() => {
        setIsLoading(false);
      })
      ?.catch(() => {
        setIsLoading(false);
      });
  }, [loading]);

  return (
    <div
      data-tooltip={tooltipText}
      className={`flex icon-button ${className ?? ''}`}
      onClick={() => {
        onClick?.();
      }}
      onKeyPress={() => {
        onClick?.();
      }}
      role="button"
      tabIndex={0}
      style={{ ...style }}
    >
      {isLoading ? (
        <div style={{ padding: `${loadingPadding}px` ?? '8px' }}>
          <div className="dot-pulse" />
        </div>
      ) : (
        <Icon {...props} style={iconStyle} />
      )}
    </div>
  );
}

export default IconButton;
