import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { editRecipe } from '../../store/actions/edit_recipe';
import { processIngredients } from '../../services/recipe-processer';
import {
  FloatingButton,
  // ImageCarousel,
  InstructionCard,
  RecipeBase,
  PortionBar,
  IngredientsList,
  RecipeImages,
  MetaDataPanel,
  TagLabel,
} from '../../components';
import './styles.css';

function Recipe() {
  const navigate = useNavigate();
  const { id } = useParams();
  const recipeStore = useSelector((state) =>
    state.recipes.recipes.find((r) => r.id === id)
  );

  if (!recipeStore) return <Navigate to="/" />;

  const baseFactor = recipeStore.portionFactor ?? 2;

  const [factor, setFactor] = useState(baseFactor);

  const calculateIngredient = (ingredientString) =>
    processIngredients(ingredientString, factor / baseFactor);

  const [recipeIngredients, setRecipeIngredients] = useState(
    [...recipeStore.recipeIngredient].map(calculateIngredient)
  );
  const [recipeImages] = useState(
    Array.isArray(recipeStore.image) ? recipeStore.image : [recipeStore.image]
  );

  useEffect(() => {
    setRecipeIngredients(
      [...recipeStore.recipeIngredient].map(calculateIngredient)
    );
  }, [factor]);

  const dispatch = useDispatch();

  const url =
    recipeStore.url ??
    recipeStore.mainEntityOfPage ??
    recipeStore.author?.url ??
    recipeStore.publisher?.url ??
    '';
  const author = recipeStore.author?.name ?? recipeStore.publisher?.name ?? '';

  return (
    <RecipeBase
      id="recipe"
      leftChildren={
        <>
          <RecipeImages
            startIndex={recipeStore.imageIndex ?? 0}
            images={recipeImages}
            name={recipeStore.name}
            description={recipeStore.description}
            url={
              recipeStore.url ??
              recipeStore.mainEntityOfPage ??
              recipeStore.author?.url ??
              recipeStore.publisher?.url ??
              ''
            }
            author={
              recipeStore.author?.name ?? recipeStore.publisher?.name ?? ''
            }
          />
          <div
            id="recipe-left-scroll-container"
            className="flex-column"
            style={{ alignItems: 'center' }}
          >
            {recipeStore.recipeCategory ? (
              <div
                className="recipe-keyword-container flex-row"
                style={{
                  gap: '5px',
                  flexWrap: 'wrap',
                  padding: '5px',
                  justifyContent: 'center',
                }}
              >
                {(Array.isArray(recipeStore.recipeCategory)
                  ? recipeStore.recipeCategory
                  : [recipeStore.recipeCategory]
                ).map((category, index) => (
                  <TagLabel
                    key={index}
                    className="clickable"
                    onClick={() => {
                      navigate(`/?filter=${JSON.stringify([category])}`);
                    }}
                  >
                    {category}
                  </TagLabel>
                )) ?? null}
              </div>
            ) : null}
            <div className="recipe-description">
              <p>{recipeStore.description}</p>
            </div>
            <div className="recipe-author">
              <p>
                {url !== '' ? (
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {author}
                  </a>
                ) : (
                  recipeStore.author?.name ?? recipeStore.publisher?.name ?? ''
                )}
              </p>
            </div>
            <MetaDataPanel
              nutrition={recipeStore.nutrition}
              prepTime={recipeStore.prepTime}
              cookingTime={recipeStore.cookingTime}
              totalTime={recipeStore.totalTime}
              factor={factor / baseFactor}
            />
            <div
              className="flex-column"
              style={{
                position: 'sticky',
                alignItems: 'center',
                top: '0',
              }}
            >
              <PortionBar
                portion={factor}
                onClick={(operation) => {
                  if (operation === '-') {
                    if (factor - 1 < 1) return;
                    setFactor(factor - 1);
                  }
                  if (operation === '+') {
                    setFactor(factor + 1);
                  }
                }}
              />
            </div>
            <IngredientsList ingredients={recipeIngredients} />
          </div>
        </>
      }
      rightChildren={recipeStore.recipeInstructions.map(
        (instruction, index) => (
          <InstructionCard
            key={index}
            index={index}
            recipeId={id}
            instruction={instruction}
            recipeIngredient={recipeIngredients.reduce(
              (p, c) => ({
                ...p,
                [c.ingredient]: {
                  amount: c.amountWithFactor,
                  unit: c.unit,
                  ingredient: c.ingredient,
                },
              }),
              {}
            )}
            timer={instruction.timer}
          />
        )
      )}
    >
      <div
        className="floating-button-container"
        style={{
          position: 'absolute',
          right: '0px',
          bottom: '0px',
          display: 'flex',
          height: 'auto',
          zIndex: '10',
        }}
      >
        <FloatingButton
          style={{
            position: 'relative',
            marginLeft: '10px',
          }}
          icon="edit"
          onClick={() => {
            dispatch(editRecipe(recipeStore));
            navigate(`../recipe-edit`);
          }}
        />
      </div>
    </RecipeBase>
  );
}

export default Recipe;
