import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import IconButton from '../../IconButton';
import ControlledInput from '../../ControlledInput';
import './styles.css';
import IconButtonRounded from '../../IconButtonRounded';
import ocr from '../../../services/ocr-handler';
import { deleteImageModal, showImageModal } from '../../../store/actions/modal';

const DragHandle = sortableHandle(() => (
  <IconButton
    style={{ alignSelf: 'center', marginTop: '-5px', marginRight: '10px' }}
    className="clickable"
    icon="menu"
    iconSize={20}
  />
));

const SortableItem = sortableElement(
  ({ value, isFirst, onDelete, onChange, onMerge, onKeyDown }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'recipe-edit' });
    return (
      <div className="flex-row" style={{ alignItems: 'center' }}>
        <DragHandle />
        <ControlledInput
          placeholder={t('default-title')}
          value={value}
          onInput={(e) => {
            onChange?.(e.target.value);
          }}
          onKeyDown={(e) => {
            onKeyDown?.(e);
          }}
        />
        <div className="flex-row controls">
          {!isFirst ? (
            <IconButton
              tooltipText="combine with above"
              className="clickable"
              icon="merge"
              iconSize={20}
              onClick={() => {
                onMerge?.();
              }}
              iconStyle={{
                transform: 'scaleY(-1)',
              }}
            />
          ) : (
            <div style={{ padding: '10px' }} />
          )}
          <IconButton
            className="clickable"
            tooltipText="delete"
            icon="close"
            iconSize={20}
            onClick={() => {
              onDelete?.();
            }}
          />
        </div>
      </div>
    );
  }
);

const SortableContainer = sortableContainer(({ children }) => (
  <div
    className="flex-column ingredients-list-edit"
    style={{
      justifyContent: 'center',
      padding: '20px',
      color: 'white',
    }}
  >
    {children}
  </div>
));

function IngredientsListEdit(
  { ingredients, onDelete, onChange, onMerge, onDragFinished },
  ref
) {
  const dispatch = useDispatch();
  const imageModal = useSelector((s) => s.modal.imageModal);
  const user = useSelector((s) => s.user);
  const newTextInput = useRef(null);
  const [ingres, setIngres] = useState(ingredients);
  const [loadingOcr, setLoadingOcr] = useState(null);

  useEffect(() => {
    setIngres(ingredients);
  }, [ingredients]); // you can manage data with it

  useEffect(async () => {
    if (imageModal.shown === false && imageModal.croppedImage) {
      const blob = await fetch(imageModal.croppedImage).then((res) =>
        res.blob()
      );
      dispatch(deleteImageModal());
      setLoadingOcr(
        ocr(blob).then((data) => {
          onChange?.(-1, data);
        })
      );
    }
  }, [imageModal]);
  const { t } = useTranslation('translation', { keyPrefix: 'recipe-edit' });

  useImperativeHandle(ref, () => ({
    getInput() {
      return newTextInput.current.value !== ''
        ? newTextInput.current.value
        : null;
    },
  }));

  const inputRef = useRef(null);

  return (
    <div
      style={{
        paddingBottom: '100px',
        display: 'block',
      }}
    >
      <SortableContainer
        onSortEnd={({ newIndex, oldIndex }) => {
          onDragFinished?.(oldIndex, newIndex);
        }}
        useDragHandle
      >
        {ingres.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            isFirst={index === 0}
            value={value}
            onDelete={() => {
              onDelete?.(index);
            }}
            onChange={(val) => {
              onChange?.(index, val);
            }}
            onMerge={(val) => {
              onMerge?.(index, val);
            }}
            onKeyDown={(e) => {
              let temp = e.target.value.split('');
              temp.splice(e.target.selectionStart, 0, '\n');
              temp = temp.join('');
              if (e.code === 'Enter') {
                onChange?.(index, temp);
              }
            }}
          />
        ))}
        <input
          ref={newTextInput}
          placeholder={t('default-ingredient')}
          onPaste={(e) => {
            const pastin = e.clipboardData.getData('text/plain');
            onChange?.(-1, pastin);
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              onChange?.(-1, e.target.value);
              newTextInput.current.focus();
              e.target.value = '';
            }
          }}
          style={{ margin: '0px 30px', marginRight: '50px' }}
        />
      </SortableContainer>
      <div
        className="flex"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}
      >
        <input
          ref={inputRef}
          style={{ display: 'none' }}
          type="file"
          accept="image/jpeg,image/png"
          onInput={(e) => {
            if (e.target.files.length > 0) {
              const url = window.URL.createObjectURL(e.target.files[0]);
              dispatch(showImageModal(url));
            }
          }}
        />
        {user.loggedIn ? (
          <IconButtonRounded
            icon="camera_enhance"
            className="shadow-box clickable"
            size={20}
            padding={15}
            loading={loadingOcr}
            loadingPadding={8}
            style={{
              alignSelf: 'center',
              backgroundColor: 'var(--background-color-primary',
            }}
            onClick={() => {
              inputRef.current.click();
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default forwardRef(IngredientsListEdit);
