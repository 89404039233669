import React from 'react';

import defaultProfilImage from '../../assets/images/default_profil_image.png';

function UserCard({ user, onLogout }) {
  return (
    <div className="user-data flex-row">
      <img
        className="profil-image"
        width="75px"
        alt=""
        height="75px"
        style={{ margin: '20px' }}
        src={user.photoURL ? user.photoURL : defaultProfilImage}
        referrerPolicy="no-referrer"
      />
      <div className="flex-column">
        <h1>{user.displayName}</h1>
        <h2>{`${user.email} (${
          user.emailVerified ? 'verified' : 'unveridified'
        })`}</h2>
      </div>
      <button
        type="button"
        onClick={() => {
          onLogout?.();
        }}
      >
        Logout
      </button>
    </div>
  );
}
export default UserCard;
