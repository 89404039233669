import React from 'react';
import './styles.css';

function RecipeBase({ leftChildren, rightChildren, children }) {
  return (
    <div className="recipe-base-container">
      <div id="left-container" className="flex fill">
        {children}
        {leftChildren}
      </div>
      <div id="right-container" className="flex fill">
        {rightChildren}
      </div>
    </div>
  );
}

export default RecipeBase;
