/* eslint-disable no-undef */
/* eslint-disable no-debugger */
import React from 'react';
import { useSelector } from 'react-redux';
import './styles.css';

function Settings() {
  const { loggedIn } = useSelector((state) => state.user);

  return (
    <div id="settings">
      <div className="settings-container">
        Settings (coming soon)
        {false ? (
          <button
            type="button"
            onClick={() => {
              // eslint-disable-next-line no-alert
              if (!loggedIn) alert('login first!');
              else deleteAll();
            }}
          >
            Delete all data (this does not delete your account)
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default Settings;
