import { LOGIN, LOGOUT } from '../actions/user';

const loginReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    loggedIn: false,
    user: {},
  },
  action
) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: !!action.payload,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
        user: null,
      };
    default:
      return state;
  }
};

export default loginReducer;
