/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextAreaAutocomplete from '../../TextAreaAutocomplete';
import Card from '../../Card';
import IconButtonRounded from '../../IconButtonRounded';
import { processIngredients } from '../../../services/recipe-processer';
import './styles.css';

function Item({ entity: { value } }) {
  return <div>{`${value}`}</div>;
}

function InstructionCardEdit({
  isFirst,
  isLast,
  instruction,
  ingredients,
  onDelete,
  onUp,
  onDown,
  onChange,
  className,
  style,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'recipe-edit' });

  const [, setInstru] = useState(instruction);
  const processedIngredients = ingredients.map((r) => processIngredients(r));

  useEffect(() => {
    setInstru(instruction);
  }, [instruction]);

  return (
    <Card
      className={`flex-column ${className ?? ''} instruction-edit-card `}
      style={{
        margin: '10px',
        marginTop: '5px',
        marginBottom: '0px',
        borderRadius: '2px',
        overflowWrap: 'break-word',
        backgroundcolor: 'var(--background-color-primary)',
        ...style,
      }}
    >
      <input
        placeholder={t('default-title')}
        value={instruction.name ?? ''}
        onInput={(e) => {
          onChange?.('name', e.target.value);
        }}
      />
      <TextAreaAutocomplete
        minChar={0}
        value={instruction.text}
        placeholder={t('default-text')}
        onInput={(e) => {
          onChange?.('text', e.target.value);
        }}
        onChange={(e) => {
          onChange?.('text', e.target.value);
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        loadingComponent={() => <span>Loading</span>}
        trigger={{
          '{': {
            dataProvider: (token) => {
              const data = processedIngredients
                .filter(
                  (ingre) =>
                    !ingre.error &&
                    !ingre.valueParseError &&
                    ingre.ingredient.includes(token)
                )
                .map((ingre) => {
                  const dataString = `1/2|${ingre.ingredient}|${
                    ingre.ingredient.split(' ')[0]
                  }`;
                  return {
                    name: `{${dataString}}`,
                    value: dataString.split('|')[1],
                  };
                })
                .slice(0, 5);
              return data;
            },
            component: Item,
            output: (item) => item.name,
          },
        }}
        listClassName="rta__list"
      />
      <div className="flex-row controls">
        {!isFirst ? (
          <IconButtonRounded
            className="clickable"
            icon="arrow_up"
            iconSize={15}
            padding="5px"
            onClick={() => {
              onUp?.();
            }}
            style={{ backgroundColor: 'var(--background-color-secondary)' }}
          />
        ) : (
          <div style={{ width: '15px', padding: '5px', margin: '0px 5px' }} />
        )}
        <IconButtonRounded
          className="clickable"
          icon="close"
          iconSize={15}
          padding="5px"
          onClick={() => {
            onDelete?.();
          }}
          style={{ backgroundColor: 'indianred' }}
        />
        {!isLast ? (
          <IconButtonRounded
            className="clickable"
            icon="arrow_down"
            iconSize={15}
            padding="5px"
            onClick={() => {
              onDown?.();
            }}
            style={{ backgroundColor: 'var(--background-color-secondary)' }}
          />
        ) : (
          <div style={{ width: '15px', padding: '5px', margin: '0px 5px' }} />
        )}
      </div>
    </Card>
  );
}

export default InstructionCardEdit;
