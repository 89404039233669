import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { expandSidebar, setActive } from '../../store/actions/sidebar';
import SidebarButton from '../SidebarButton';
import CountDownSmall from '../CountDownSmall';
import defaultProfilImage from '../../assets/images/default_profil_image.png';
import './styles.css';

function Sidebar({ onClickQuestion }) {
  const { t } = useTranslation('translation', { keyPrefix: 'sidebar' });

  const user = useSelector((state) => state.user);
  const sidebarStore = useSelector((state) => state.sidebar);
  const countdownStore = useSelector((state) => state.countdown);

  const dispatch = useDispatch();

  const [expand, toggleExpand] = useState(sidebarStore.expanded);
  const [activeIndex, setActiveIndex] = useState(sidebarStore.active);
  const sidebar = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    setActiveIndex(sidebarStore.active);
  }, [sidebarStore]);

  const toggleExpandFunction = () => {
    toggleExpand(!expand);
    dispatch(expandSidebar(!expand));
  };
  const setActiveFunction = (active) => {
    setActiveIndex(active);
    dispatch(setActive(active));
  };

  return (
    <div className={`sidebar flex ${expand ? 'expand' : ''}`} ref={sidebar}>
      <SidebarButton
        icon={expand ? 'menu_open' : 'menu'}
        className="expand-button"
        onClick={() => toggleExpandFunction()}
        innerStyle={{
          height: '20px',
          marginBottom: '10px',
        }}
      />
      <SidebarButton
        icon="book"
        labeled={expand ? t('recipes') : false}
        onClick={() => {
          setActiveFunction('recipes');
          navigate(``);
        }}
        className={activeIndex === 'recipes' ? 'active' : ''}
      />
      {countdownStore?.counter.length > 0 ? (
        <div className="flex-column countdown-container" style={{}}>
          {countdownStore.counter.map((c) => (
            <CountDownSmall
              isPlaying={c.state === 'running'}
              key={c.id}
              style={{
                paddingBottom: '10px',
                marginLeft: expand ? '11px' : 'auto',
              }}
              id={c.id}
              recipe_id={c.recipe_id}
              name={c.name}
              duration={c.duration}
              labeled={expand ? c.name : false}
            />
          ))}
        </div>
      ) : null}
      <SidebarButton
        icon="question"
        className="sidebar-button-question"
        labeled={expand ? t('settings') : false}
        onClick={() => {
          onClickQuestion?.();
        }}
      />
      <SidebarButton
        icon="settings"
        labeled={expand ? t('settings') : false}
        onClick={() => {
          // setActiveFunction('settings');
          // navigate(`settings`);
          setActiveFunction('profil');
          navigate(`user`);
        }}
        className={activeIndex === 'settings' ? 'active' : ''}
      />
      <div
        role="button"
        style={{
          // width: '100%',
          marginLeft: expand ? '14.5px' : 'auto',
        }}
        onClick={() => {
          setActiveFunction('profil');
          navigate(`user`);
        }}
        className={`profil-image-container ${
          activeIndex === 'profil' ? 'active' : ''
        }`}
      >
        <img
          className="profil-image"
          width="45px"
          alt=""
          height="45px"
          src={
            user?.loggedIn && user?.user?.photoURL
              ? user?.user?.photoURL
              : defaultProfilImage
          }
          referrerPolicy="no-referrer"
        />
      </div>
    </div>
  );
}

export default Sidebar;
