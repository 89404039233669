import firebase from 'firebase/compat/app';
import constants from '../assets/constants/constants.json';

export const getRecipes = () =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (res, rej) => {
    const currentUser = await firebase.auth().currentUser;
    const token = await currentUser.getIdToken(true);
    // eslint-disable-next-line no-undef
    fetch(`${constants.ENDPOINT}/get-recipes`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => res(data))
      .catch((err) => rej(err));
  });

export const saveRecipes = async (payload) => {
  const currentUser = await firebase.auth().currentUser;
  const token = await currentUser.getIdToken(true);
  if (token === null) Promise.resolve(false);
  // eslint-disable-next-line no-undef
  fetch(`${constants.ENDPOINT}/save-recipes`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.text())
    .then((data) => {
      Promise.resolve(data);
    });
};
export const deleteAll = () =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (res, rej) => {
    const currentUser = await firebase.auth().currentUser;
    const token = await currentUser.getIdToken(true);
    // eslint-disable-next-line no-undef
    fetch(`${constants.ENDPOINT}/delete-recipes`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => res(data))
      .catch((err) => rej(err));
  });
