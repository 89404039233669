import {
  ADD_RECIPE,
  ADD_OR_UPDATE_RECIPE,
  DELETE_RECIPE,
  ADD_RECIPES,
  SYNC_RECIPES,
} from '../actions/recipes';

const recipesReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    recipes: [
      {
        '@context': 'http:/\\/schema.org',
        '@type': 'Recipe',
        name: 'Lasagne Bolognese',
        url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
        mainEntityOfPage:
          'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
        description:
          'Ein tolles Essen für Ihre Gäste ist die Lasagne Bolognese. Überzeugen Sie sich selbst von diesem köstlichen Rezept.',
        comment: [
          {
            '@type': 'Comment',
            url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
            dateCreated: '2016-04-20T21:38:33',
            text: 'Dieses Rezept ist perfekt!',
            creator: {
              '@type': 'Person',
              name: 'Tsambika',
            },
          },
          {
            '@type': 'Comment',
            url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
            dateCreated: '2016-05-01T16:44:36',
            text: 'Meine Gattin und ich haben es auch schon probiert.Es schmeckte uns einfach herrlich.Super Rezept!',
            creator: {
              '@type': 'Person',
              name: 'schurli',
            },
          },
          {
            '@type': 'Comment',
            url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
            dateCreated: '2016-05-10T21:07:54',
            text: 'ich hab es versucht war sehr lecker:) nur bei der sauce hatte ich etwas probleme da es zu wenig wurde und es klumpen gab:) ',
            creator: {
              '@type': 'Person',
              name: 'vanivanessa',
            },
          },
          {
            '@type': 'Comment',
            url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
            dateCreated: '2021-04-17T16:05:22',
            text: 'Mir war das viel zu wenig Bechamelsoße. Habe die doppelte Menge gemacht und fands dann genau passend. Ansonsten war es sehr lecker.',
            creator: {
              '@type': 'Person',
              name: 'Lebensmittel-selbstg',
            },
          },
          {
            '@type': 'Comment',
            url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
            dateCreated: '2016-06-17T19:14:34',
            text: 'Ist für Gäste ideal, da man es schon vorher herrichten kann. Für Vegetatiere kann man Gemüse nehmen.',
            creator: {
              '@type': 'Person',
              name: 'Silviatempelmayr',
            },
          },
          {
            '@type': 'Comment',
            url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
            dateCreated: '2016-08-03T09:11:52',
            text: 'Es klingt komisch, aber ich verwende immer Cheddar für die Lasagne. Durch Zufall hatte ich einmal größere Mengen Cheddar zu Hause, deshalb nahm ich ihn und es war die beste Lasagne, die wir bisher gegessen hatten!',
            creator: {
              '@type': 'Person',
              name: 'walnuss',
            },
          },
          {
            '@type': 'Comment',
            url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
            dateCreated: '2016-08-07T09:04:17',
            text: 'Sehr gutes Rezept. Zur Bechamel-Sauce gebe ich allerdings nach dem Aufkochen noch frisch geriebenen Parmesan und ein Ei und etwas Salz dazu.',
            creator: {
              '@type': 'Person',
              name: 'Tsambika',
            },
          },
          {
            '@type': 'Comment',
            url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
            dateCreated: '2016-11-05T19:01:29',
            text: 'Wenn es nach meinem Sohn ginge würde Lasagne jeden Tag bei uns am Tisch stehen! ;-) Schmeckt am nächsten Tag aufgewärmt noch besser.',
            creator: {
              '@type': 'Person',
              name: 'Wibi79',
            },
          },
          {
            '@type': 'Comment',
            url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
            dateCreated: '2017-01-26T15:13:17',
            text: 'Tolles Rezept - Lasagne kommt bei uns relativ häufig auf den Tisch, sind ein großer Fan davon! Freu mich schon auf das nächste Mal!',
            creator: {
              '@type': 'Person',
              name: 'verena_w',
            },
          },
          {
            '@type': 'Comment',
            url: 'https:/\\/www.gutekueche.at\\/lasagne-bolognese-rezept-3999',
            dateCreated: '2017-01-31T18:00:01',
            text: 'Lasagne könnte ich auch wiedermal machen. Die mögen bei uns daheim eigentlich alle sehr. Sehr lecker.',
            creator: {
              '@type': 'Person',
              name: 'Silviatempelmayr',
            },
          },
        ],
        image: [
          'https:/\\/www.gutekueche.at\\/storage\\/media\\/recipe\\/102205\\/conv\\/lasagne-bolognese-default.jpg',
          'https:/\\/www.gutekueche.at\\/storage\\/media\\/recipe\\/102206\\/3999_Lasagne-Bolognese_1.jpg',
          'https:/\\/www.gutekueche.at\\/storage\\/media\\/recipe\\/102207\\/3999_Lasagne-Bolognese_1.jpg',
        ],
        author: {
          '@type': 'Organization',
          name: 'GuteKueche.at',
        },
        publisher: {
          '@type': 'Organization',
          name: 'GuteKueche.at',
          logo: [
            {
              '@type': 'ImageObject',
              url: 'https:/\\/www.gutekueche.at\\/assets\\/img\\/logos\\/600x60_gkatlogo.png',
              width: '600',
              height: '60',
            },
            {
              '@type': 'ImageObject',
              url: 'https:/\\/www.gutekueche.at\\/assets\\/img\\/logos\\/600x600_gkatlogo.png',
              width: '600',
              height: '600',
            },
          ],
        },
        datePublished: '2008-06-18',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: 4.63,
          reviewCount: 34682,
          worstRating: 1,
          bestRating: 5,
        },
        video: {
          '@type': 'VideoObject',
          name: 'Lasagne Bolognese',
          description:
            'Der Klassiker unter den Lasagne Rezepte ist sicherlich die Lasagne Bolognese. Wir zeigen euch in diesem Video wie einfach man dieses italienische Gericht zubereiten kann.',
          thumbnailUrl: [
            'https:/\\/i.vimeocdn.com\\/video\\/1136244445-784ea433733ad70a2ccf897744ba9e2af62c768223d840cc820240847391c6e5-d_1280x720?r=pad',
          ],
          contentUrl: null,
          embedUrl: 'https:/\\/player.vimeo.com\\/video\\/548803350',
          uploadDate: '2021-05-13T10:55:48+00:00',
          duration: 'PT01M27S',
        },
        keywords: 'Lasagne Bolognese, Lasagne Rezepte',
        prepTime: 'PT15M',
        cookTime: 'PT40M',
        totalTime: 'PT55M',
        recipeYield: '4 Portionen',
        recipeCategory: 'Lasagne Rezepte',
        recipeIngredient: [
          '200 g Gouda (gerieben, zum Bestreuen)',
          '12 Stk Lasagneblätter',
          '1 Schuss Olivenöl (für die Form)',
          '80 g Butter',
          '80 g Mehl',
          '800 ml Milch',
          '1 TL Muskat (gestrichen)',
          '1 Prise Pfeffer (weiß)',
          '1 Prise Salz',
          '1 Prise Basilikum',
          '1 Stk Karotte (gehackt)',
          '3 EL Ketchup',
          '1 Stk Knoblauchzehe (gepreßt)',
          '1 Schuss Öl',
          '1 Prise Oregano',
          '1 Prise Pfeffer',
          '400 g Rinderfaschiertes',
          '1 Prise Salz',
          '1 Prise Thymian',
          '1 Dose Tomaten',
          '5 EL Tomatenmark',
          '1 Stk Zwiebel (gehackt)',
        ],
        recipeCuisine: 'Italienische Rezepte',
        recipeInstructions: [
          {
            name: 'Bolognesesauce',
            text: 'Öl in einem Topf erhitzen, {1/4|Gouda (gerieben, zum Bestreuen)|Gouda} Faschiertes darin anrösten. Zwiebeln, Karotte und Knoblauch fein hacken, dazugeben {50%|Rinderfaschiertes|Faschiertes} und weiter anbraten. Tomaten und Tomatenmark sowie das Ketchup und alle Gewürze dazu und auf kleinster Stufe ca. 10 Min. köcheln lassen.',
          },
          {
            name: 'Bechamelsauce',
            text: 'Butter in einem Topf zerlassen, Mehl zufügen, mit einem Schneebesen sofort umrühren. Jetzt ganz langsam, unter {1/2|Butter} ständigem rühren die Milch zufügen. Langsam aufkochen und rühren bis die Sauce dicklich ist. Mit Muskat und weißem Pfeffer würzen.',
          },
          {
            name: 'Lasagne',
            text: 'Auflaufform mit Olivenöl einfetten. Nun abwechselnd die Lasagneblätter und Bolognesesauce einschichten, mit den Lasagneblätter beginnen, danach die Saucen usw. - mit der Sauce abschließen.',
          },
          {
            name: null,
            text: 'Ganz zum Schluss oben drauf kommt die Bechamelsauce, nur noch frisch geriebenen Gouda drauf geben und im vorgeheizten Ofen, bei 180° C Heißluft ca. 30 Min. backen. Geschenktipp: Spezielle Rezeptkarten-Sets mit passender Rezeptbox, erhältlich in unserem neuen Onlineshop: shop.gutekueche.at',
          },
        ],
        id: '6d3505d8-0dc4-400d-b092-df8a5ea6d08d',
      },
    ],
    state: {
      loading: false,
      loaded: false,
    },
  },
  action
) => {
  switch (action.type) {
    case ADD_RECIPE:
      return {
        ...state,
        recipes: [...state.recipes, action.payload],
      };
    case ADD_OR_UPDATE_RECIPE:
      {
        const index = state.recipes.findIndex(
          (r) => r.id === action.payload.id
        );
        const s = state;
        if (index !== -1) {
          s.recipes[index] = action.payload;
          return {
            ...s,
          };
        }
      }
      return {
        ...state,
        recipes: [...state.recipes, action.payload],
      };
    case DELETE_RECIPE:
      return {
        ...state,
        recipes: state.recipes.filter((r) => r.id !== action.payload),
      };
    case ADD_RECIPES:
      return {
        ...state,
        recipes: [...state.recipes, ...action.payload],
      };
    case SYNC_RECIPES:
      return {
        ...state,
        recipes: [...state.recipes, ...action.payload.recipes].reduce(
          (p, c) => {
            if (p.find((r) => r.id === c.id)) return p;
            return [...p, c];
          },
          []
        ),
      };
    default:
      return state;
  }
};

export default recipesReducer;
