import React from 'react';
import IconButtonRounded from '../IconButtonRounded';
import './styles.css';

function FloatingButton({ className, icon, onClick, ...props }) {
  return (
    <IconButtonRounded
      className={`floating-button shadow-box clickable ${className ?? ''}`}
      icon={icon}
      padding="20px"
      onClick={() => {
        onClick?.();
      }}
      {...props}
    />
  );
}

export default FloatingButton;
